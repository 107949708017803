import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 21"
      {...props}
    >
      <path
        stroke="#051653"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19 17.5v-2.709a1.8 1.8 0 0 0-.43-1.213c-.809-.953-3.585-1.563-4.685-1.328-.858.182-1.458 1.043-2.045 1.629a14.47 14.47 0 0 1-5.43-5.42c.587-.585 1.45-1.184 1.633-2.04.234-1.097-.375-3.855-1.322-4.668a1.8 1.8 0 0 0-1.198-.436H2.808c-1.034 0-1.895.928-1.8 1.969C1 12.249 8 19.314 17.025 19.307c1.046.095 1.978-.77 1.973-1.806M12.5 4.814c.737.144 1.469.47 2 1 .531.532.856 1.263 1 2M13 1.314c1.531.17 2.91.912 4 2 1.09 1.09 1.828 2.47 2 4"
      />
    </svg>
  );
}
const SvgIconCall = React.memo(Icon);
export default SvgIconCall;
