import React, { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import classNames from "classnames";
import Autocomplete from "react-google-autocomplete";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// types
import { IconType } from "../../../icons/icon.types";

// styles
import styles from "./styles.module.css";

const TODAY = new Date();

type InputFieldIconProps = {
  name: string;
  control: any;
  rules: any;
  label: string;
  errors: any;
  textarea?: boolean;
  Icon?: React.MemoExoticComponent<
    (originalProps: IconType) => React.JSX.Element
  > | null;
  stylesContainer?: string;
  stylesInput?: string;
  stylesMain?: string;
  typeNumber?: boolean;
};

const InputFieldIcon: FunctionComponent<InputFieldIconProps> = ({
  name,
  control,
  typeNumber,
  rules,
  label,
  errors,
  textarea,
  Icon,
  stylesContainer,
  stylesInput,
  stylesMain,
}) => {
  const checkTypeNumber = typeNumber ? "number" : "text";
  const checkInputType = name === "email" ? name : checkTypeNumber;
  return (
    <div className={classNames([styles.containerInput, stylesMain])}>
      <p className={styles.label}>{label}</p>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          textarea ? (
            <div className={classNames([styles.container, stylesContainer])}>
              <textarea
                {...field}
                className={classNames([
                  !isEmpty(errors[name]) ? "input-error" : "",
                  stylesInput,
                ])}
              />
            </div>
          ) : (
            <div className={classNames([styles.container, stylesContainer])}>
              {(name === "departure" || name === "destination") && (
                <Autocomplete
                  apiKey={"AIzaSyBj19IvMBdtzePRHudPHaX89Mv2riA_-8Y"}
                  onPlaceSelected={(place) => {
                    field.onChange(place.formatted_address);
                  }}
                  language="en"
                />
              )}
              {name !== "date" &&
                name !== "departure" &&
                name !== "destination" && (
                  <input
                    {...field}
                    className={classNames([
                      !isEmpty(errors[name]) ? "input-error" : "",
                      stylesInput,
                    ])}
                    type={checkInputType}
                  />
                )}
              {name === "date" && (
                <DatePicker
                  selected={new Date(field.value || TODAY)}
                  onChange={(date) => field.onChange(date)}
                  dateFormat="dd/MM/yyyy"
                />
              )}
              {Icon ? (
                <Icon className={styles.icon} />
              ) : (
                <div className={styles.icon} />
              )}
            </div>
          )
        }
      />
      {errors[name] && (
        <span className={"error"}>
          {isEmpty(errors[name]?.message)
            ? name === "phone"
              ? `Phone is invalid`
              : `This field is required`
            : errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default InputFieldIcon;
