import React from "react";

// constants
import { FEATURES_DATA, SERVICES_DATA } from "../../../assets/main.constants";

// images
import gerb from "../../../assets/images/gerb.png";
import compass from "../../../assets/images/compass.png";

export const DEFAULT_FIRST_MAIN = {
  title: "OUR EXPERTISE",
  type: "icon-full" as const,
  subTitle:
    "Our comprehensive range of services is designed to meet the diverse and evolving needs of our global clientele, ensuring efficient, reliable, and cost-effective logistics solutions for every shipment.",
  items: [...FEATURES_DATA],
};

export const DEFAULT_SECOND_MAIN = {
  titleFirst: "Avantages of partnering",
  titleSecond: "with air alliance us",
  subTitle:
    "By choosing Air Alliance US, you are opting for a reliable, efficient, and customer-focused partner in air cargo transportation. Our comprehensive approach to logistics ensures that your shipping needs are met with the highest standards of quality, safety, and efficiency.",
  type: "title-subtitle" as const,
  items: [
    {
      id: 21,
      title: "Superior Quality",
      titleSecond: "Assurance",
      subTitle:
        "Our specialized engine and accessory inspection services cover a wide range of aviation components, guaranteeing optimal performance and reliability of critical equipment.",
    },
    {
      id: 22,
      title: "Cost-Effective",
      titleSecond: "Solutions",
      subTitle:
        "Leveraging our extensive network of carriers, we negotiate competitive rates to provide our clients with cost- efficient shipping options, optimizing transportation budgets without compromising quality",
    },
    {
      id: 23,
      title: "Unwavering",
      titleSecond: "Commitment to Safety",
      subTitle:
        "Leveraging our extensive network of carriers, we negotiate competitive rates to provide our clients with cost- efficient shipping options, optimizing transportation budgets without compromising quality",
    },
    {
      id: 24,
      title: "Rapid",
      titleSecond: "Transit Times",
      subTitle:
        "Capitalizing on the inherent speed of air freight, we offer swift delivery solutions that meet tight deadlines for time-sensitive shipments, significantly reducing transit times.",
    },
    {
      id: 25,
      title: "Secure",
      titleSecond: "Cargo Handling",
      subTitle:
        "Our dedication to safety extends to every stage of the delivery process. We implement stringent measures to ensure proper handling of goods from pickup to final delivery, minimizing risks of damage or loss",
    },
    {
      id: 26,
      title: "Specialized",
      titleSecond: "Expertise",
      subTitle:
        "Understanding the unique requirements of various cargo types, we match shipments with carriers possessing specific expertise, ensuring smooth and efficient transportation.",
    },
    {
      id: 27,
      title: "Global",
      titleSecond: "Logistics Network",
      subTitle:
        "Our partnerships with international carriers enable us to offer truly global reach, facilitating seamless logistics for businesses with diverse shipping needs across the world.",
    },
    {
      id: 28,
      title: "Streamlined",
      titleSecond: "Operations",
      subTitle:
        "As your single point of contact for air cargo transportation, we simplify the logistics process, handling all coordination and communication with carriers so you can focus on your core business.",
    },
    {
      id: 29,
      title: "Dedicated",
      titleSecond: "Customer Support",
      subTitle:
        "As your single point of contact for air cargo transportation, we simplify the logistics process, handling all coordination and communication with carriers so you can focus on your core business.",
    },
  ],
};

export const DEFAULT_THIRD_MAIN = {
  id: 3,
  title: "Why air alliance us?",
  subTitle:
    "Air Alliance US offers efficient, cost-effective air cargo services backed by decades of industry experience. We prioritize transparency, reliability, and customer satisfaction, providing tailored solutions for diverse shipping needs",
  type: "titles-icons" as const,
  items: [
    {
      id: 31,
      title: "№1",
      subTitle: "Price leadership in the industry",
    },
    {
      id: 32,
      title: "24/7",
      subTitle:
        "Uninterrupted 24/7 customer support, ensuring we’re always available for you.",
    },
    {
      id: 33,
      title: "+5,000",
      subTitle: "A thriving network of over 5,000 pleased customers",
    },
    {
      id: 34,
      title: "+30",
      subTitle: "More than three decades, 30+ years, of industry experience",
    },
    {
      id: 35,
      image: gerb,
    },
    {
      id: 36,
      image: compass,
    },
  ],
};

export const DEFAULT_FOURTH_MAIN = {
  id: 4,
  title: "Our approach",
  type: "list" as const,
  subTitle: (
    <>
      We prioritize transparency in our operations and pricing structure. Our
      business model, including <span>commission-free</span> options, allows us
      to offer competitive rates without compromising on service quality.
    </>
  ),
  titleList: (
    <>
      At <span>Air Alliance US</span>, we are dedicated to:
    </>
  ),
  list: [
    <>
      Maintaining the highest standards of <span>safety</span> and{" "}
      <span>security</span>
    </>,
    <>Continuously improving our services</>,
    <>Staying ahead of industry trends and technologies</>,
    <>
      Building <span>long-term relationships</span> with our clients
    </>,
  ],
};

export const DEFAULT_FIFTH_MAIN = {
  title: "FEATURED MISSIONS",
  type: "images-full" as const,
  items: [...SERVICES_DATA, ...SERVICES_DATA],
};
