import React, {useEffect} from 'react';
import Footer from "../../components/Footer";
import aboutBlocks from "./utils/aboutBlocks";
import scrollAddClass from "../../utils/scrollAddClass";
import '../../styles/AboutUs.scss';

import backgroundImage from "../../assets/images/background_About.jpeg";
import airplane from "../../assets/images/airplane_about.png";


const AboutUs = () => {

  useEffect(() => {
    scrollAddClass('.about-us .block', 'animate');
  }, [])

  return (
    <div className="about-us">
      <div
        className={`background`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={`first-container`}>
          <h1>Advantages of Cooperation with Air Alliance US</h1>
          <p>Overall, cooperation with Air Alliance US offers numerous benefits, making it a reliable and efficient partner for businesses seeking air cargo transportation and logistics solutions.</p>
          <img
            src={airplane}
            className={'airplane'}
          />
        </div>

        <div className={`blocks`}>
          {aboutBlocks?.map((block => (
            <div className={`block`}>
              <div className={`info-block`}>
                <h3>
                  {block?.label}
                </h3>

                <div className={'line-container'}>
                  <div className={'line'}/>
                </div>

                <p>
                  {block?.description}
                </p>
              </div>

              <div
                className={`background-block right`}
                style={{ backgroundImage: `url(${block?.image})` }}
              />
            </div>
          )))}
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default AboutUs;
