import React, { FunctionComponent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

// helps
import { addClass, removeClass } from "../../utils/scrollAddClass";

// components
import { IconPlane } from "../../icons/components";
import Button from "../Button";

// icons
import logoDark from "../../assets/svg/logo_dark.svg";
import iconMobileMenu from "../../assets/svg/menu_mobile.svg";
import iconMobileMenuWhite from "../../assets/svg/menu_mobile_white.svg";

// constants
import { PATH_SCREENS } from "./pages";

// styles
import "./../../styles/Menu.scss";
import styles from "./styles.module.css";

const Menu: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHome = location?.pathname === "/";

  const mobileMenu = useRef(null);
  useOutsideMenuClick(mobileMenu);

  const [openMobile, setOpenMobile] = useState(false);

  const toFeedback = () => {
    navigate("/feedback");
  };

  useEffect(() => {
    removeClass(".menu-top", "show-menu");
    setTimeout(() => addClass(".menu-top", "show-menu"), 100);
  }, [location]);

  function useOutsideMenuClick(
    ref: React.MutableRefObject<HTMLDivElement | null>
  ) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target))
          setOpenMobile(false);
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (location.hash.includes("footer")) {
      const element = document.getElementById("footer");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [location]);

  return (
    <>
      <div className={classNames([`menu-top`, styles.menuTop])}>
        <div
          className={classNames([
            `menu-top-container`,
            styles.menuTopContainer,
          ])}
        >
          <ul>
            <li>
              <Link to={PATH_SCREENS[0].to}>
                <img src={logoDark} alt={"logo"} />
              </Link>
            </li>
            <li>
              <Link to={`${PATH_SCREENS[0].to}#aboutUs`}>
                {PATH_SCREENS[0].title}
              </Link>
            </li>
            <li>
              <Link to={PATH_SCREENS[1].to}>{PATH_SCREENS[1].title}</Link>
            </li>
            <li>
              <Link to={`${location.pathname}${PATH_SCREENS[2].to}`}>
                {PATH_SCREENS[2].title}
              </Link>
            </li>
            <li>
              <Link to={PATH_SCREENS[3].to}>{PATH_SCREENS[3].title}</Link>
            </li>
          </ul>
          <Button
            title={"Inquire Now"}
            onClick={toFeedback}
            Icon={<IconPlane className={"icon"} />}
            className={styles.button}
          />

          <div className={"menu-mobile"}>
            <div className={"logo"}>
              <Link to={PATH_SCREENS[0].to}>
                <img src={logoDark} alt={"logo"} />
              </Link>
            </div>
            <div className={"btn"} onClick={() => setOpenMobile(!openMobile)}>
              <img
                src={isHome ? iconMobileMenu : iconMobileMenuWhite}
                alt={"menu"}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        ref={mobileMenu}
        className={`menu-mobile-left ${
          openMobile ? "show-animation-menu" : "hide-animation-menu"
        }`}
      >
        <ul>
          <li onClick={() => setOpenMobile(false)}>
            <Link to={PATH_SCREENS[0].to}>{PATH_SCREENS[0].title}</Link>
          </li>
          <li onClick={() => setOpenMobile(false)}>
            <Link to={PATH_SCREENS[1].to}>{PATH_SCREENS[1].title}</Link>
          </li>
          <li onClick={() => setOpenMobile(false)}>
            <Link to={`${location.pathname}${PATH_SCREENS[2].to}`}>
              {PATH_SCREENS[2].title}
            </Link>
          </li>
          <li onClick={() => setOpenMobile(false)}>
            <Link to={PATH_SCREENS[3].to}>{PATH_SCREENS[3].title}</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Menu;
