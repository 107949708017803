import React, { FunctionComponent } from "react";
import {
  IconArrowLeftMobilePagination,
  IconArrowRightMobilePagination,
} from "../../icons/components";

// styles
import styles from "./styles.module.css";

type PaginationMobileProps = {
  indexShow: number;
  setIndexShow: (index: number) => void;
  lastIndex: number;
};
const PaginationMobile: FunctionComponent<PaginationMobileProps> = ({
  indexShow,
  setIndexShow,
  lastIndex,
}) => {
  const onPressNext = () => {
    if (indexShow === lastIndex) {
      setIndexShow(0);
    } else {
      setIndexShow(indexShow + 1);
    }
  };

  const onPressPrev = () => {
    if (indexShow === 0) {
      setIndexShow(lastIndex);
    } else {
      setIndexShow(indexShow - 1);
    }
  };
  return (
    <div className={styles.containerPagination}>
      <div onClick={onPressPrev}>
        <IconArrowLeftMobilePagination width={"42px"} />
      </div>
      <div onClick={onPressNext}>
        <IconArrowRightMobilePagination width={"42px"} />
      </div>
    </div>
  );
};

export default PaginationMobile;
