export const DEFAULT_VALUES_INPUTS = {
  1: {
    departure: "",
    destination: "",
    date: "",
    weight: "",
    dimensions: "",
  },
  2: {
    totalWeight: "",
    totalVolume: "",
    largestSize: "",
    dimensions: "",
    additionalInformation: "",
  },
  3: {
    name: "",
    company: "",
    country: "",
    state: "",
    email: "",
    phone: "",
    mobile: "",
  },
};
