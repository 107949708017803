import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";

// components
import InputFieldIcon from "../Services/utils/InputFieldIcon";
import ButtonBlue from "../../components/ButtonBlue";

// icons
import { IconCalendar, IconLocation } from "../../icons/components";

// styles
import styles from "./styles.module.css";

type FirstInputsProps = {
  values: {
    departure: string;
    destination: string;
    date: string;
    weight: string;
    dimensions: string;
  };
  onNext: (data: {
    departure: string;
    destination: string;
    date: string;
    weight: string;
    dimensions: string;
  }) => void;
};

const FirstInputs: FunctionComponent<FirstInputsProps> = ({
  values,
  onNext,
}) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: values,
  });
  const onSubmit = async (data: {
    departure: string;
    destination: string;
    date: string;
    weight: string;
    dimensions: string;
  }) => {
    onNext(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formFirst}>
      <div className={styles.twoInputs}>
        <InputFieldIcon
          name="departure"
          control={control}
          rules={{ required: true }}
          label="Departure"
          errors={errors}
          Icon={IconLocation}
        />
        <InputFieldIcon
          name="destination"
          control={control}
          rules={{ required: true }}
          label="Destination"
          errors={errors}
          Icon={IconLocation}
        />
      </div>
      <div className={styles.twoInputs}>
        <InputFieldIcon
          name="date"
          control={control}
          rules={{ required: true }}
          label="Date"
          errors={errors}
          Icon={IconCalendar}
        />
        <InputFieldIcon
          name="weight"
          control={control}
          rules={{ required: true }}
          label="Weight"
          errors={errors}
          typeNumber
        />
      </div>
      <InputFieldIcon
        name="dimensions"
        control={control}
        rules={{ required: true }}
        label="Dimensions"
        errors={errors}
        typeNumber
      />
      <ButtonBlue
        title="NEXT"
        styleContainer={styles.containerButton}
        styleButton={styles.button}
        type="submit"
      />
    </form>
  );
};

export default FirstInputs;
