import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 43 42"
      {...props}
    >
      <g
        stroke="#001A72"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        opacity={0.5}
      >
        <path d="M7.327 19.25h28M7.327 12.25v21a3.5 3.5 0 0 0 3.5 3.5h21a3.5 3.5 0 0 0 3.5-3.5v-21a3.5 3.5 0 0 0-3.5-3.5h-21a3.5 3.5 0 0 0-3.5 3.5M26.577 5.25v7M16.077 5.25v7" />
      </g>
    </svg>
  );
}
const SvgIconCalendar = React.memo(Icon);
export default SvgIconCalendar;
