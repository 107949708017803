import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 97 98"
      {...props}
    >
      <g fill="#051653" clipPath="url(#icon-pharmaceuticals_svg__a)">
        <path d="M23.862 24.565a1.883 1.883 0 1 0 .001 3.767 1.883 1.883 0 0 0-.001-3.767M40.805 59.768h-5.648v-5.647a1.883 1.883 0 0 0-3.765 0v5.647h-5.647a1.883 1.883 0 0 0 0 3.765h5.647v5.648a1.883 1.883 0 0 0 3.765 0v-5.648h5.648a1.883 1.883 0 0 0 0-3.765" />
        <path d="M91.614 61.463c-4.162-7.208-13.363-9.675-20.572-5.513l-7.647 4.415V44.017a9.48 9.48 0 0 0-1.882-5.647L52.1 25.82v-5.344a5.66 5.66 0 0 0 3.765-5.324v-8.66A5.654 5.654 0 0 0 50.217.846H16.332a5.654 5.654 0 0 0-5.648 5.648v8.66a5.66 5.66 0 0 0 3.765 5.323v5.344L5.036 38.37a9.48 9.48 0 0 0-1.882 5.647v36.459c0 5.19 4.223 9.412 9.413 9.412H42.82c4.22 7.076 13.322 9.444 20.457 5.324l22.824-13.177c7.146-4.127 9.694-13.325 5.512-20.572M14.449 6.493c0-1.038.845-1.883 1.883-1.883h33.885c1.038 0 1.883.845 1.883 1.883v8.66a1.885 1.885 0 0 1-1.883 1.882H16.332a1.885 1.885 0 0 1-1.883-1.883zm-6.4 34.136 9.789-13.052c.244-.326.376-.722.376-1.13V20.8h30.12v3.765H31.393a1.883 1.883 0 0 0 0 3.765h17.884l9.223 12.298a5.7 5.7 0 0 1 1.09 2.763H6.96a5.7 5.7 0 0 1 1.09-2.762m4.518 45.494a5.654 5.654 0 0 1-5.648-5.647v-1.901h34.203a15.1 15.1 0 0 0 .09 7.549zm30.031-11.295H6.92V47.155h52.71V62.54l-11.411 6.59a14.95 14.95 0 0 0-5.62 5.7m18.798 17.124c-5.403 3.12-12.306 1.274-15.428-4.132A11.294 11.294 0 0 1 50.1 72.388l10.218-5.9 11.295 19.564zm22.823-13.178-9.344 5.395-11.296-19.563 9.345-5.395a11.29 11.29 0 0 1 15.43 4.134c3.135 5.433 1.228 12.332-4.135 15.43" />
      </g>
      <defs>
        <clipPath id="icon-pharmaceuticals_svg__a">
          <path fill="#fff" d="M.198.845h96.39v96.39H.198z" />
        </clipPath>
      </defs>
    </svg>
  );
}
const SvgIconPharmaceuticals = React.memo(Icon);
export default SvgIconPharmaceuticals;
