import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 43 42"
      {...props}
    >
      <g
        stroke="#051653"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        opacity={0.5}
      >
        <path d="M21.327 24.5a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5" />
        <path d="M21.327 36.75c7.732-3.5 14-9.768 14-17.5s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 17.5" />
      </g>
    </svg>
  );
}
const SvgIconLocation = React.memo(Icon);
export default SvgIconLocation;
