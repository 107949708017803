import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 61 61"
      {...props}
    >
      <path
        stroke="#001A72"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M43.315 30.543h-25M33.315 40.543l10-10-10-10"
      />
    </svg>
  );
}
const SvgIconArrowRight = React.memo(Icon);
export default SvgIconArrowRight;
