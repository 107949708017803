import React, {
  FunctionComponent,
  HTMLAttributeAnchorTarget,
  ReactElement,
} from "react";

// styles
import styles from "./styles.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";

type ButtonProps = {
  title: string;
  onClick: () => void;
  Icon?: ReactElement | null;
  className?: string;
  toLink?: string;
  target?: HTMLAttributeAnchorTarget;
};

const Button: FunctionComponent<ButtonProps> = ({
  title,
  onClick,
  Icon = null,
  className,
  toLink,
  target,
}) => {
  if (toLink) {
    return (
      <Link
        className={classNames(styles.container, className)}
        to={toLink}
        target={target}
        rel="noreferrer"
      >
        {Icon}
        <span className={styles.title}>{title}</span>
      </Link>
    );
  }
  return (
    <div className={classNames(styles.container, className)} onClick={onClick}>
      {Icon}
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default Button;
