import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { animations } from "./utils/animations";

// components
import Information from "./Information";

// images
import backgroundImage1 from "../../assets/images/home-first-clouds.png";
import backgroundImage2 from "../../assets/images/background_Home_Second.jpeg";

// styles
import "../../styles/Home.scss";

type StartMainProps = {
  setIsStart: (value: boolean) => void;
};

const StartMain: FunctionComponent<StartMainProps> = ({ setIsStart }) => {
  const location = useLocation();
  const [documentWidth, setDocumentWidth] = useState(0);
  const [stepAnimation, setStepAnimation] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = useMemo(() => documentWidth < 768, [documentWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const element = ref.current;
      if (element) {
        if (ref.current && ref.current.scrollTop > 1 && stepAnimation < 2) {
          setStepAnimation(2);
          setTimeout(() => setStepAnimation(3), 2000);
        }
      }
    };

    if (ref.current) {
      ref.current?.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref.current) {
        ref.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setStepAnimation(1), 100);
  }, []);

  useEffect(() => {
    setStepAnimation(0);
    setTimeout(() => setStepAnimation(1), 100);
  }, [location]);

  useEffect(() => {
    if (stepAnimation === 3) setIsStart(false);
  }, [stepAnimation]);

  useEffect(() => {
    const updateDocumentHeight = () => {
      const width = document.body.scrollWidth;
      setDocumentWidth(width);
    };

    window.addEventListener("resize", updateDocumentHeight);

    updateDocumentHeight();

    return () => {
      window.removeEventListener("resize", updateDocumentHeight);
    };
  }, []);

  return (
    <div
      className={`home ${stepAnimation < 2 && "hideScrollbar"}`}
      ref={ref}
      style={{ height: "100vh" }}
    >
      <div
        className={`background-blue ${
          animations[stepAnimation]?.backgroundBlueStyle
        } ${
          animations[stepAnimation]?.backgroundBlue
            ? "background-show"
            : "background-hide"
        }`}
        style={{
          background: `linear-gradient(180deg, #72A4CB ${
            70 + 10 * stepAnimation
          }%, #A7BDCB ${160 - 20 * stepAnimation}%)`,
        }}
      />
      <div
        className={`background-blue ${
          animations[stepAnimation]?.backgroundBlueStyle
        } ${
          animations[stepAnimation]?.backgroundBlue
            ? "background-show"
            : "background-hide"
        }`}
        style={{
          width: "200%",
          height: "100%",
          backgroundImage: `url(${backgroundImage1})`,
          top: "120px",
        }}
      />
      <div
        className={`background-red ${
          animations[stepAnimation]?.backgroundRedStyle
        } ${
          animations[stepAnimation]?.backgroundBlue
            ? "background-hide"
            : "background-show"
        } ${animations[stepAnimation]?.backgroundBlue && "hide"}`}
        style={{ backgroundImage: `url(${backgroundImage2})` }}
      />
      <div
        className={`gradient-footer-before ${
          stepAnimation !== 4 && "hide-opacity"
        } ${animations[stepAnimation]?.backgroundBlue && "hide"}`}
      />

      <div
        className={`gradient-footer-black-after ${
          stepAnimation !== 4 && "hide-opacity"
        } ${animations[stepAnimation]?.backgroundBlue && "hide"}`}
      />

      <Information step={stepAnimation} setStep={setStepAnimation} />
    </div>
  );
};

export default StartMain;
