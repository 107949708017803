import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";

// components
import InputFieldIcon from "../Services/utils/InputFieldIcon";
import ButtonBlue from "../../components/ButtonBlue";

// styles
import styles from "./styles.module.css";
import classNames from "classnames";

type SecondInputsProps = {
  values: {
    totalWeight: string;
    totalVolume: string;
    largestSize: string;
    dimensions: string;
    additionalInformation: string;
  };
  onNext: (values: {
    totalWeight: string;
    totalVolume: string;
    largestSize: string;
    dimensions: string;
    additionalInformation: string;
  }) => void;
  onBack: () => void;
};

const SecondInputs: FunctionComponent<SecondInputsProps> = ({
  values,
  onNext,
  onBack,
}) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: values,
  });

  const onSubmit = async (data: {
    totalWeight: string;
    totalVolume: string;
    largestSize: string;
    dimensions: string;
    additionalInformation: string;
  }) => {
    onNext(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formSecond}>
      <div className={styles.left}>
        <InputFieldIcon
          name="totalWeight"
          control={control}
          rules={{ required: true }}
          label={"Total Weight"}
          errors={errors}
          typeNumber
        />
        <InputFieldIcon
          name="totalVolume"
          control={control}
          rules={{ required: true }}
          label={"Total Volume"}
          errors={errors}
          typeNumber
        />
        <InputFieldIcon
          name="largestSize"
          control={control}
          rules={{ required: true }}
          label={"Largest Size"}
          errors={errors}
          typeNumber
        />
        <InputFieldIcon
          name="dimensions"
          control={control}
          rules={{ required: true }}
          label={"Dimensions"}
          errors={errors}
          typeNumber
        />
      </div>
      <div className={styles.right}>
        <InputFieldIcon
          name="additionalInformation"
          control={control}
          rules={{ required: true }}
          label={"Additional Information"}
          errors={errors}
          stylesContainer={styles.textareaContainer}
          stylesInput={styles.textarea}
          stylesMain={styles.mainInput}
          textarea
        />
        <div className={styles.buttons}>
          <ButtonBlue
            title="BACK"
            styleContainer={styles.containerButton}
            styleButton={classNames([styles.button, styles.buttonBack])}
            onClick={onBack}
          />
          <ButtonBlue
            title="NEXT"
            styleContainer={classNames([
              styles.containerButton,
              styles.buttonNextContainer,
            ])}
            styleButton={classNames([styles.button, styles.buttonNext])}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default SecondInputs;
