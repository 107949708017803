import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 43 43"
      {...props}
    >
      <path
        stroke="#001A72"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.452 21.51h17.5M19.452 14.51l-7 7 7 7"
      />
    </svg>
  );
}
const SvgIconArrowLeftMobilePagination = React.memo(Icon);
export default SvgIconArrowLeftMobilePagination;
