import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

// components
import Footer from "../../components/Footer";
import CloudsCustom from "../../components/CloudsCustom";
import ButtonBlue from "../../components/ButtonBlue";

// constants
import { FIRST_DATA_MISSIONS, SECOND_DATA_CONTACTS } from "./constants";

// icons
import {
  IconArrowLeftPagination,
  IconArrowRightPagination,
} from "../../icons/components";

// images
import plane from "../../assets/images/missions/many-plan.png";
import planeMobile from "../../assets/images/missions/many-plan-mobile.png";

// styles
import styles from "./styles.module.css";
import "../../styles/Contacts.scss";

const Missions: FunctionComponent = () => {
  const navigate = useNavigate();

  const toMission = (id: number) => {
    navigate(`/mission?missionId=${id}`);
  };

  return (
    <div className="contacts">
      <div
        className={`background`}
        style={{
          background: `linear-gradient(180deg, #72A4CB 130%, #A7BDCB 160%)`,
          paddingBottom: "100px",
        }}
      >
        <div className={styles.first}>
          <img src={planeMobile} alt="plane" className={styles.imageMobile} />
          <h2>{FIRST_DATA_MISSIONS.title}</h2>
          <p>{FIRST_DATA_MISSIONS.subTitle}</p>
          <img src={plane} alt="plane" className={styles.image} />
        </div>
        <div className={styles.secondFull}>
          <div className={styles.second}>
            {SECOND_DATA_CONTACTS.map((item, index) => (
              <div key={index} className={styles.item}>
                <img src={item.image} alt="contact" className={styles.image} />
                <div className={styles.content}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
                <ButtonBlue
                  title="READ MORE"
                  styleButton={styles.button}
                  styleContainer={styles.containerButton}
                  onClick={() => toMission(item.id)}
                />
              </div>
            ))}
          </div>
          <div className={styles.pagination}>
            <div className={styles.button}>
              <IconArrowLeftPagination width="12px" />
            </div>
            <div className={classNames([styles.button, styles.active])}>
              <p>1</p>
            </div>
            <div className={styles.button}>
              <p>2</p>
            </div>
            <div className={styles.button}>
              <p>3</p>
            </div>
            <div className={styles.button}>
              <p>...</p>
            </div>
            <div className={styles.button}>
              <p>90</p>
            </div>
            <div className={styles.button}>
              <IconArrowRightPagination width="12px" />
            </div>
          </div>
        </div>
      </div>
      <CloudsCustom />
      <Footer />
    </div>
  );
};

export default Missions;
