import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 17"
      {...props}
    >
      <path
        stroke="#051653"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18 1.314H2a.997.997 0 0 0-1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-12a.997.997 0 0 0-1-1"
      />
      <path
        stroke="#051653"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M1.293 1.607 8.586 8.9a2 2 0 0 0 2.828 0l7.293-7.293"
      />
    </svg>
  );
}
const SvgIconMail = React.memo(Icon);
export default SvgIconMail;
