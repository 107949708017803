import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";

// components
import FeaturedComponent from "../../components/FeaturedComponent";
import Footer from "../../components/Footer";
import CloudsCustom from "../../components/CloudsCustom";
import PaginationMobile from "../../components/PaginationMobile";

//icons
import {
  IconArrowLeftLittle,
  IconArrowRight,
  IconArrowRightLittle,
} from "../../icons/components";

//constants
import {
  DEFAULT_FIFTH_MAIN,
  DEFAULT_FIRST_MAIN,
  DEFAULT_FOURTH_MAIN,
  DEFAULT_SECOND_MAIN,
  DEFAULT_THIRD_MAIN,
} from "./utils/constants";

//styles
import styles from "./styles.module.css";

const BodyMain: FunctionComponent = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPagination, setShowPagination] = useState(0);
  const [show, setShow] = useState(0);

  const isMobile = useMemo(() => windowWidth < 768, [windowWidth]);
  const isNext = useMemo(
    () => show + (isMobile ? 1 : 4) < DEFAULT_FIFTH_MAIN.items.length,
    [isMobile, show]
  );
  const showSecondItems = useMemo(
    () =>
      isMobile
        ? DEFAULT_SECOND_MAIN.items.slice(showPagination, showPagination + 1)
        : DEFAULT_SECOND_MAIN.items,
    [isMobile, showPagination]
  );

  const isBack = useMemo(() => show > 0, [show]);

  const onPressNext = () => {
    if (isNext) setShow(show + 1);
  };

  const onPressBack = () => {
    if (isBack) setShow(show - 1);
  };

  const toServices = () => {
    navigation("/services");
  };

  useEffect(() => {
    if (location.hash.includes("footer")) {
      const element = document.getElementById("footer");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShow(0);
  }, [isMobile]);

  return (
    <div className={styles.container}>
      <div
        className={classNames([styles.background, styles.containerBackground])}
        style={{
          background: `linear-gradient(180deg, #72A4CB 130%, #A7BDCB 160%)`,
        }}
      >
        <CloudsCustom />
        <div className={styles.first}>
          <div className={styles.body}>
            <div className={styles.containerTitle} onClick={toServices}>
              <h1 className={styles.title}>{DEFAULT_FIRST_MAIN.title}</h1>
              <IconArrowRight
                width="60px"
                fill="transparent"
                style={{
                  fill: "transparent",
                }}
                className={styles.icon}
              />
            </div>
            <p className={styles.subTitle}>{DEFAULT_FIRST_MAIN.subTitle}</p>
            {DEFAULT_FIRST_MAIN.items && (
              <div
                className={classNames([
                  styles.containerItems,
                  styles.containerItemsFirst,
                ])}
              >
                {DEFAULT_FIRST_MAIN.items
                  .slice(show, show + (isMobile ? 1 : 4))
                  .map((item, index) => (
                    <div key={item.id} className={styles.containerItem}>
                      <div className={styles.containerItemUp}>
                        {item.icon && (
                          <item.icon
                            height="97px"
                            style={{
                              fill: "transparent",
                            }}
                          />
                        )}
                        <div className={styles.containerItemTitles}>
                          <h1 className={styles.itemTitle}>{item.title}</h1>
                          <p className={styles.itemSubTitle}>
                            {item.littleTitle}
                          </p>
                        </div>
                      </div>
                      <Link
                        to={`/service?serviceId=${item.id}`}
                        className={styles.itemLink}
                      >
                        LEARN MORE
                      </Link>
                    </div>
                  ))}
              </div>
            )}
            {DEFAULT_FIRST_MAIN.type === "icon-full" && (
              <div className={styles.containerInfoText}>
                <p className={styles.infoText}>
                  Discover Our Full Range of Services
                </p>
                <div className={styles.containerIconsInfoText}>
                  <IconArrowLeftLittle
                    width="30px"
                    style={{
                      fill: "transparent",
                      cursor: "pointer",
                      opacity: isBack ? 1 : 0.5,
                    }}
                    onClick={onPressBack}
                  />
                  <IconArrowRightLittle
                    width="30px"
                    style={{
                      fill: "transparent",
                      cursor: "pointer",
                      opacity: isNext ? 1 : 0.5,
                    }}
                    onClick={onPressNext}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.second}>
          <div className={styles.body}>
            <div className={styles.containerTitle} onClick={toServices}>
              <h1 className={styles.title}>{DEFAULT_SECOND_MAIN.titleFirst}</h1>
              <span>
                <h1 className={styles.title}>
                  {DEFAULT_SECOND_MAIN.titleSecond}
                </h1>
                <IconArrowRight
                  width="60px"
                  fill="transparent"
                  style={{
                    fill: "transparent",
                  }}
                />
              </span>
            </div>

            <p className={styles.subTitle}>{DEFAULT_SECOND_MAIN.subTitle}</p>

            {showSecondItems && (
              <div
                className={classNames([
                  styles.containerItems,
                  styles.containerItemsFirst,
                ])}
              >
                {showSecondItems.map((item, index) => (
                  <div
                    key={item.id}
                    className={classNames([
                      styles.containerItem,
                      styles.containerItemFirst,
                    ])}
                  >
                    <div className={styles.containerItemUp}>
                      <div
                        className={classNames([
                          styles.containerItemTitles,
                          styles.containerItemTitlesFirst,
                        ])}
                      >
                        <div>
                          <h1
                            className={classNames([
                              styles.itemTitle,
                              styles.itemTitleFirst,
                            ])}
                          >
                            {item.title}
                          </h1>
                          <h1
                            className={classNames([
                              styles.itemTitle,
                              styles.itemTitleFirst,
                            ])}
                          >
                            {item.titleSecond}
                          </h1>
                        </div>
                        <p
                          className={classNames([
                            styles.itemSubTitle,
                            styles.itemSubTitleFirst,
                          ])}
                        >
                          {item.subTitle}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <PaginationMobile
              indexShow={showPagination}
              setIndexShow={setShowPagination}
              lastIndex={DEFAULT_SECOND_MAIN.items.length - 1}
            />
          </div>
        </div>
        <div id="aboutAs" className={styles.third}>
          <div className={styles.body}>
            <div
              className={classNames([
                styles.containerTitle,
                styles.containerTitleSecond,
              ])}
              onClick={toServices}
            >
              <h1 className={styles.title}>{DEFAULT_THIRD_MAIN.title}</h1>
              <IconArrowRight
                width="60px"
                fill="transparent"
                style={{
                  fill: "transparent",
                }}
              />
            </div>

            <p className={styles.subTitle}>{DEFAULT_THIRD_MAIN.subTitle}</p>

            {DEFAULT_THIRD_MAIN.items && (
              <div
                className={classNames([
                  styles.containerItems,
                  styles.containerItemsSecond,
                ])}
              >
                {DEFAULT_THIRD_MAIN.items.map((item, index) => (
                  <div
                    key={item.id}
                    className={classNames([
                      styles.containerItem,
                      styles.containerItemSecond,
                    ])}
                    style={{
                      gridArea: `item${index}`,
                    }}
                  >
                    <div className={styles.containerItemUp}>
                      {item.image && (
                        <img
                          src={item.image}
                          alt={`item-${item.id}`}
                          className={styles.image}
                        />
                      )}
                      <div
                        className={classNames([
                          styles.containerItemTitles,
                          styles.containerItemTitlesFirst,
                        ])}
                      >
                        <div>
                          <h1
                            className={classNames([
                              styles.itemTitle,
                              styles.itemTitleSecond,
                            ])}
                          >
                            {item.title}
                          </h1>
                        </div>
                        <p
                          className={classNames([
                            styles.itemSubTitle,
                            styles.itemSubTitleSecond,
                          ])}
                          style={{
                            width: isMobile
                              ? "96%"
                              : index === 0
                              ? "120px"
                              : index === 0 || index === 3
                              ? "150px"
                              : index > 0
                              ? "96%"
                              : "100%",
                          }}
                        >
                          {item.subTitle}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.fourth}>
          <div className={styles.body}>
            <div className={styles.containerTitle} onClick={toServices}>
              <h1 className={styles.title}>{DEFAULT_FOURTH_MAIN.title}</h1>
              <IconArrowRight
                width="60px"
                fill="transparent"
                style={{
                  fill: "transparent",
                }}
                className="fourthTitleArrow"
              />
            </div>

            <p className={styles.subTitle}>{DEFAULT_FOURTH_MAIN.subTitle}</p>

            {(DEFAULT_FOURTH_MAIN.titleList || DEFAULT_FOURTH_MAIN.list) && (
              <div className={styles.containerList}>
                {DEFAULT_FOURTH_MAIN.titleList && (
                  <h1 className={styles.titleList}>
                    {DEFAULT_FOURTH_MAIN.titleList}
                  </h1>
                )}
                {DEFAULT_FOURTH_MAIN.list && (
                  <ul className={styles.list}>
                    {DEFAULT_FOURTH_MAIN.list.map((item, index) => (
                      <li
                        key={`item-list-${index}`}
                        className={styles.titleList}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        <FeaturedComponent title={DEFAULT_FIFTH_MAIN.title} />
        <div
          style={{
            paddingTop: "100px",
          }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default BodyMain;
