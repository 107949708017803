import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// components
import CloudsCustom from "../../components/CloudsCustom";

// constants
import { SECOND_DATA_CONTACTS } from "../Missions/constants";

// images
import bigPlane from "../../assets/images/missions/big-plane.png";

// styles
import styles from "./styles.module.css";
import Footer from "../../components/Footer";

const Mission: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMobile = useMemo(() => windowWidth < 768, [windowWidth]);
  const missionId = useMemo(
    () => new URLSearchParams(location.search).get("missionId"),
    [location.search]
  );
  const selectedMission = useMemo(
    () =>
      SECOND_DATA_CONTACTS.find((item) => item.id === Number(missionId)) ||
      null,
    [missionId]
  );

  useEffect(() => {
    if (missionId === null) navigate(-1);
  }, [missionId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!selectedMission) return null;
  return (
    <div className="services">
      <div
        className={`background`}
        style={{
          background: `linear-gradient(180deg, #72A4CB 130%, #A7BDCB 160%)`,
          paddingBottom: "60px",
        }}
      >
        <CloudsCustom type="service" />
        <div className={styles.first}>
          <div className={styles.body}>
            <h2 className={styles.title}>{selectedMission.title}</h2>
            {!isMobile && <p>{selectedMission.description}</p>}
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
        </div>
        <div className={styles.second}>
          <div className={styles.body}>
            <img
              src={selectedMission.id === 1 ? bigPlane : selectedMission.image}
              alt="big-plane"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mission;
