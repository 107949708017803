import imgAbout1 from "../../../assets/images/about-info/Rectangle_1551.jpeg";
import imgAbout2 from "../../../assets/images/about-info/Rectangle_1552.jpeg";
import imgAbout3 from "../../../assets/images/about-info/Rectangle_1553.jpeg";
import imgAbout4 from "../../../assets/images/about-info/Rectangle_1554.jpeg";
import imgAbout6 from "../../../assets/images/about-info/Rectangle_1556.jpeg";
import imgAbout7 from "../../../assets/images/about-info/Rectangle_1557.jpeg";
import imgAbout8 from "../../../assets/images/about-info/Rectangle_1558.jpeg";
import imgAbout9 from "../../../assets/images/about-info/Rectangle_1559.jpeg";
import imgAbout10 from "../../../assets/images/about-info/Rectangle_1560.jpeg";


const aboutBlocks = [
  {
    label: 'High-Quality Delivery',
    description: 'Air Alliance US offers specialized engine and accessory inspections, covering a range of aviation components. These assessments ensure the optimal performance and reliability of engines and related equipment.',
    image: imgAbout1
  },
  {
    label: 'Cost Savings',
    description: 'As an air cargo transportation broker, Air Alliance US negotiates competitive rates with its network of carriers. This allows clients to benefit from cost-effective shipping solutions, optimizing their transportation expenses.',
    image: imgAbout2
  },
  {
    label: 'Safety Guarantee',
    description: 'Safety is a top priority for Air Alliance US. By collaborating with trusted and reliable airlines and freight carriers, they ensure that all necessary safety protocols and standards are followed throughout the transportation process.',
    image: imgAbout3
  },
  {
    label: 'Fast Delivery',
    description: 'Air freight is known for its speed compared to other modes of transportation. Air Alliance US leverages this advantage to provide fast delivery solutions, reducing transit times and meeting tight deadlines for time-sensitive shipments.',
    image: imgAbout4
  },
  // {
  //   label: 'Goods Insurance',
  //   description: 'Air Alliance US offers insurance options for cargo shipments, providing added protection and peace of mind to clients. In the rare event of loss or damage during transit, the insurance coverage helps mitigate financial risks.',
  //   image: imgAbout5
  // },
  {
    label: 'Safety of Goods on Delivery',
    description: 'The company\'s focus on safety extends to the entire delivery process. They ensure that goods are handled properly at each stage of transportation, from pickup to final delivery, minimizing the risk of damage or loss.',
    image: imgAbout6
  },
  {
    label: 'Expert Handling',
    description: ' Air Alliance US understands the unique requirements of different types of cargo and selects carriers with expertise in handling specific goods. This expertise translates to a smoother and more efficient transportation process.',
    image: imgAbout7
  },
  {
    label: 'Global Reach',
    description: 'Through its partnerships with international carriers, Air Alliance US offers a global reach, enabling clients to ship goods to various destinations worldwide. This expansive network facilitates seamless logistics for businesses with diverse shipping needs.',
    image: imgAbout8
  },
  {
    label: 'Streamlined Logistics',
    description: 'By acting as a single point of contact for air cargo transportation, Air Alliance US simplifies the logistics process for clients. They handle the coordination and communication with carriers, allowing businesses to focus on their core operations.',
    image: imgAbout9
  },
  {
    label: ' Customer Support',
    description: 'Air Alliance US provides excellent customer support, addressing any inquiries or concerns promptly. Their attentive service ensures that clients receive the assistance they need throughout the shipping process.',
    image: imgAbout10
  },
]

export default aboutBlocks
