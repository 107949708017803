import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fill="#051653"
        d="M9.145 1.903A1.697 1.697 0 0 1 10.83 0c1.041 0 1.986.611 2.412 1.561l3.394 7.56C18.125 9.08 19.62 9 21.11 9a3 3 0 0 1 0 6c-1.49 0-2.984-.08-4.474-.12l-3.394 7.559A2.64 2.64 0 0 1 10.83 24a1.697 1.697 0 0 1-1.684-1.903l.905-7.396-2.827-.076-1.18 2.064A2.6 2.6 0 0 1 3.785 18c-.926 0-1.676-.75-1.676-1.676V14.45a2.5 2.5 0 0 1 0-4.9V7.676C2.11 6.75 2.86 6 3.785 6c.933 0 1.795.5 2.258 1.31l1.18 2.065 2.827-.076zM10.83 1.5a.197.197 0 0 0-.196.221l1.107 9.033-5.374.145-1.625-2.844a1.1 1.1 0 0 0-.956-.555.176.176 0 0 0-.176.176v3.297L2.62 11h-.01a1 1 0 0 0 0 2h.01l.99.027v3.297c0 .097.08.176.176.176.395 0 .76-.212.956-.555l1.625-2.844 5.374.145-1.107 9.033a.197.197 0 0 0 .196.221c.45 0 .86-.264 1.044-.675l3.803-8.472 5.35.145.083.002a1.5 1.5 0 1 0-.083-2.998l-5.35.145-3.803-8.472A1.14 1.14 0 0 0 10.83 1.5"
      />
    </svg>
  );
}
const SvgIconPlane = React.memo(Icon);
export default SvgIconPlane;
