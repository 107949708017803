import { animations } from "./utils/animations";
import React, { FunctionComponent } from "react";

import airPlane from "../../assets/images/airplane.png";
import roundBtn from "../../assets/svg/home_round_btn.svg";

type InformationProps = {
  step: number;
  setStep: (step: number) => void;
};

const Information: FunctionComponent<InformationProps> = ({
  step,
  setStep,
}) => {
  return (
    <div className={`information ${animations[step]?.infoHide && "hide"}`}>
      <div className={`infoContainer ${animations[step]?.infoStyle}`}>
        <h1>air alliance us: your trusted partner in air cargo solutions</h1>
        <p>
          Air Alliance US is an established leader in the air cargo broker
          industry, providing efficient, reliable, and cost-effective logistics
          solutions. Our commitment to transparency and customer satisfaction
          has positioned us at the forefront of the field, serving a diverse
          range of clients with exceptional air freight services.
        </p>
      </div>

      <img
        src={airPlane}
        className={`airplane ${animations[step]?.airplaneStyle}`}
        alt={""}
      />

      <p
        onClick={() => {
          setStep(2);
          setTimeout(() => setStep(3), 2000);
        }}
        className={`roundContainer ${animations[step]?.infoStyle}`}
        style={{
          cursor: "pointer",
        }}
      >
        <img src={roundBtn} alt={""} />
      </p>
    </div>
  );
};

export default Information;
