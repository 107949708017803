import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 98 98"
      {...props}
    >
      <path
        stroke="#051653"
        strokeMiterlimit={10}
        strokeWidth={3.241}
        d="M87.13 10.685H10.46v45.986h76.67zM25.803 22.212v23.013M41.145 22.212v23.013M56.487 22.212v23.013M71.829 22.212v23.013M14.316 87.395a7.671 7.671 0 1 0 0-15.342 7.671 7.671 0 0 0 0 15.342ZM33.474 87.395a7.671 7.671 0 1 0 0-15.342 7.671 7.671 0 0 0 0 15.342ZM64.158 87.395a7.671 7.671 0 1 0 0-15.342 7.671 7.671 0 0 0 0 15.342ZM83.315 87.395a7.671 7.671 0 1 0 0-15.342 7.671 7.671 0 0 0 0 15.342ZM90.986 56.711H6.645v15.342h84.341z"
      />
    </svg>
  );
}
const SvgIconCargoTransport = React.memo(Icon);
export default SvgIconCargoTransport;
