import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 95 98"
      {...props}
    >
      <path
        fill="#051653"
        d="M12.596 29.834a3.093 3.093 0 0 0-3.09 3.09 3.093 3.093 0 0 0 3.09 3.089 3.093 3.093 0 0 0 3.09-3.09 3.093 3.093 0 0 0-3.09-3.089M12.596 21.235a3.093 3.093 0 0 0-3.09 3.09 3.093 3.093 0 0 0 3.09 3.09 3.093 3.093 0 0 0 3.09-3.09 3.093 3.093 0 0 0-3.09-3.09M76.17 39.727h-.003a2.318 2.318 0 1 0 .003 0"
      />
      <path
        fill="#051653"
        d="M48.581 5.943v-2.78A2.317 2.317 0 0 0 46.264.844H36.07a2.317 2.317 0 0 0-2.317 2.317V17.89H24.78v-4.533a2.317 2.317 0 0 0-2.317-2.317H3.173a2.317 2.317 0 0 0-2.317 2.317v71.366a2.317 2.317 0 0 0 2.317 2.317h4.182a6.23 6.23 0 0 0-1.401 3.938 6.264 6.264 0 0 0 6.256 6.257 6.264 6.264 0 0 0 6.258-6.257 6.23 6.23 0 0 0-1.402-3.938h61.655a6.23 6.23 0 0 0-1.402 3.938 6.264 6.264 0 0 0 6.257 6.257 6.264 6.264 0 0 0 6.257-6.257 6.23 6.23 0 0 0-1.4-3.938h4.025a2.317 2.317 0 0 0 2.317-2.317V32.596a2.317 2.317 0 0 0-2.317-2.317h-.31V18.455a2.317 2.317 0 0 0-2.316-2.317H79.637a2.317 2.317 0 0 0-2.318 2.317v2.78h-.231a5.335 5.335 0 0 1-5.33-5.329c0-5.494-4.47-9.963-9.963-9.963zM12.211 92.6a1.624 1.624 0 0 1-1.623-1.623c0-.894.728-1.622 1.622-1.622s1.623.728 1.623 1.623c0 .894-.728 1.622-1.623 1.622m71.365 0a1.624 1.624 0 0 1-1.622-1.623c0-.894.727-1.622 1.622-1.622s1.623.728 1.623 1.623c0 .894-.728 1.622-1.623 1.622M24.78 30.195h2.336v3.553c0 .853.692 1.544 1.545 1.544h25.488c.853 0 1.544-.691 1.544-1.544v-3.553h1.86v18.653H24.78zm15.468-4.326h2.008v6.334h-2.008zm21.94 22.979V34.913h27.953v13.935zm19.766-28.076h5.56v9.507h-5.56zM48.58 10.577h13.214c2.939 0 5.33 2.39 5.33 5.33 0 5.493 4.47 9.962 9.963 9.962h.231v4.41H62.188V20.207a2.317 2.317 0 0 0-2.317-2.317h-11.29zm0 12.975v-1.028h8.973v4.581H54.15c-.854 0-1.545.692-1.545 1.545v3.553h-7.259v-6.334h.919a2.317 2.317 0 0 0 2.317-2.317M38.386 5.48h5.561v15.756h-5.56zm-4.634 17.045v1.028a2.317 2.317 0 0 0 2.317 2.317h1.09v6.334h-6.953V28.65c0-.853-.692-1.545-1.545-1.545h-3.882v-4.581zM20.145 82.406H5.49V15.674h14.655zm4.634-28.598h32.775v28.598H24.78zm37.409 28.598V53.808h27.953v28.598z"
      />
      <path
        fill="#051653"
        d="M54.149 42.398H28.66a1.545 1.545 0 1 0 0 3.09h25.488a1.544 1.544 0 1 0 0-3.09M49.051 72.983H33.76a1.544 1.544 0 1 0 0 3.09H49.05a1.544 1.544 0 1 0 0-3.09M49.051 67.886H33.76a1.545 1.545 0 1 0 0 3.09H49.05a1.544 1.544 0 1 0 0-3.09M41.173 61.552a2.317 2.317 0 1 0 0-4.634h-.004a2.318 2.318 0 0 0 .003 4.634"
      />
    </svg>
  );
}
const SvgIconEquipment = React.memo(Icon);
export default SvgIconEquipment;
