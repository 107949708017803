import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

// components
import Footer from "../../components/Footer";
import ButtonBlue from "../../components/ButtonBlue";
import FeaturedComponent from "../../components/FeaturedComponent";
import CloudsCustom from "../../components/CloudsCustom";

// icons
import InputFieldIcon from "./utils/InputFieldIcon";
import { IconCalendar, IconLocation } from "../../icons/components";

// constants
import {
  FIRST_DATA_CONTACTS,
  SECOND_DATA_CONTACTS,
  THIRD_DATA_CONTACTS,
  FIFTH_DATA_SERVICE,
} from "./constants";

// styles
import styles from "./styles.module.css";
import "../../styles/Contacts.scss";

const Services: FunctionComponent = () => {
  const navigation = useNavigate();

  const onToService = (id: number) => {
    navigation(`/service?serviceId=${id}`);
  };

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      departure: "",
      destination: "",
      date: "",
      weight: "",
      dimensions: "",
    },
  });

  const onSubmit = async (data: {
    departure: string;
    destination: string;
    date: string;
    weight: string;
    dimensions: string;
  }) => {
    navigation("/feedback", {
      state: {
        valuesFirst: data,
        step: 2,
      },
    });
  };

  return (
    <div className="contacts">
      <CloudsCustom />
      <div
        className={`background`}
        style={{
          background: `linear-gradient(180deg, #72A4CB 130%, #A7BDCB 160%)`,
          paddingBottom: "60px",
        }}
      >
        <div className={styles.first}>
          <div className={styles.titles}>
            <h1>{FIRST_DATA_CONTACTS.title}</h1>
            <p>{FIRST_DATA_CONTACTS.description}</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.twoInputs}>
              <InputFieldIcon
                name="departure"
                control={control}
                rules={{ required: true }}
                label="Departure"
                errors={errors}
                Icon={IconLocation}
              />
              <InputFieldIcon
                name="destination"
                control={control}
                rules={{ required: true }}
                label="Destination"
                errors={errors}
                Icon={IconLocation}
              />
            </div>
            <div className={styles.twoInputs}>
              <InputFieldIcon
                name="date"
                control={control}
                rules={{ required: true }}
                label="Date"
                errors={errors}
                Icon={IconCalendar}
              />
              <InputFieldIcon
                name="weight"
                control={control}
                rules={{ required: true }}
                label="Weight"
                errors={errors}
              />
            </div>
            <InputFieldIcon
              name="dimensions"
              control={control}
              rules={{ required: true }}
              label="Dimensions"
              errors={errors}
            />
            <ButtonBlue
              title="INQUIRE NOW"
              styleContainer={styles.containerButton}
              styleButton={classNames([
                styles.button,
                Object.keys(errors).length && styles.buttonDisable,
              ])}
              type="submit"
            />
          </form>
        </div>
        <div className={styles.second}>
          {SECOND_DATA_CONTACTS.map((item, index) => (
            <div key={index} className={styles.item}>
              <img src={item.image} alt="contact" className={styles.image} />
              <div className={styles.content}>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>
              <ButtonBlue
                title="LEARN MORE"
                styleButton={styles.button}
                onClick={() => onToService(item.id)}
              />
            </div>
          ))}
        </div>
        <FeaturedComponent title={FIFTH_DATA_SERVICE.title} />
        <div className={styles.third}>
          <div className={styles.body}>
            <div className={styles.titles}>
              <h1>{THIRD_DATA_CONTACTS.title}</h1>
              <p>{THIRD_DATA_CONTACTS.description}</p>
            </div>
            <ButtonBlue
              title="INQUIRE NOW"
              onClick={() => {}}
              styleButton={styles.button}
            />
            <div className={styles.items}>
              {THIRD_DATA_CONTACTS.items.map((item, index) => (
                <div
                  key={index}
                  className={styles.item}
                  style={{
                    gridArea: `item${index}`,
                  }}
                >
                  <div className={styles.icon}>
                    {item.image ? (
                      <img src={item.image} alt="icon" />
                    ) : (
                      <h1>{item.title}</h1>
                    )}
                  </div>
                  <p>{item.subTitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
