import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

// components
import clouds from "../../assets/images/CloudsCustom";

// styles
import styles from "./styles.module.css";

type CloudsType = {
  type?: string;
};

const CloudsCustom: FunctionComponent<CloudsType> = ({ type }) => {
  const [documentWidth, setDocumentWidth] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const isMobile = useMemo(() => documentWidth < 768, [documentWidth]);
  const stepTop = useMemo(() => {
    return (
      documentHeight / (type === "feedback" ? 10 : Object.values(clouds).length)
    );
  }, [documentHeight]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        (window.scrollY || document.documentElement.scrollTop) >
          documentHeight &&
        scrollPosition < (window.scrollY || document.documentElement.scrollTop)
      ) {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
      }
    };

    const updateDocumentHeight = () => {
      const height = document.body.scrollHeight;
      const width = document.body.scrollWidth;
      setDocumentHeight(height - 100);
      setDocumentWidth(width);
    };

    window.addEventListener("resize", updateDocumentHeight);
    window.addEventListener("scroll", handleScroll);

    updateDocumentHeight();
    handleScroll();

    return () => {
      window.removeEventListener("resize", updateDocumentHeight);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={classNames([styles.container, isMobile && styles.mobile])}
      style={{
        width: isMobile ? "100%" : documentWidth,
        height: isMobile ? "100vh" : documentHeight,
      }}
    >
      {Object.values(clouds)
        .slice(0, type === "feedback" ? 9 : Object.values(clouds).length)
        .map((cloud, index) => {
          const top = (stepTop * index) / (index > 3 ? 1 : 2);
          return (
            <img
              key={index}
              className={classNames([
                styles.cloud,
                styles[`cloud${index + 1}`],
                scrollPosition + window.innerHeight < top && styles.disabled,
                type && styles[type],
              ])}
              src={cloud}
              alt={""}
              style={{
                top,
              }}
            />
          );
        })}
    </div>
  );
};

export default CloudsCustom;
