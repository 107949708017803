import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 25"
      {...props}
    >
      <g
        stroke="#051653"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#icon-call-out_svg__a)"
      >
        <path d="M21.5 19.5v-2.709a1.8 1.8 0 0 0-.429-1.213c-.81-.953-3.585-1.563-4.686-1.328-.858.182-1.458 1.043-2.044 1.629a14.47 14.47 0 0 1-5.43-5.42c.586-.585 1.449-1.184 1.632-2.04.234-1.097-.375-3.855-1.322-4.668a1.8 1.8 0 0 0-1.198-.436H5.308c-1.034 0-1.895.928-1.8 1.969-.008 8.965 6.992 16.03 16.019 16.023 1.045.095 1.977-.77 1.973-1.806M14.6 10.214l7.072-7.071" />
        <path d="M14.6 4.557v5.657h5.657" />
      </g>
      <defs>
        <clipPath id="icon-call-out_svg__a">
          <path fill="#fff" d="M.5.314h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
const SvgIconCallOut = React.memo(Icon);
export default SvgIconCallOut;
