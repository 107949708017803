type AnimationType = {
  infoHide: boolean;
  aboutHide: boolean;
  servicesHide: boolean;
  servicesAirHide: boolean;
  airplaneStyle: string;
  infoStyle: string;
  aboutStyle: string;
  backgroundBlue: boolean;
  backgroundBlueStyle: string;
  backgroundRedStyle: string;
  cloudStyle: {
    1: string;
    2: string;
    3: string;
  };
};

export const animations: Record<number, AnimationType> = {
  0: {
    infoHide: false,
    aboutHide: true,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "",
    infoStyle: "container-hide",
    aboutStyle: "container-hide",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-start",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "",
      2: "",
      3: "",
    },
  },
  1: {
    infoHide: false,
    aboutHide: true,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-right",
    infoStyle: "",
    aboutStyle: "container-hide",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-start",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "",
      2: "",
      3: "",
    },
  },
  2: {
    infoHide: false,
    aboutHide: true,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-left",
    infoStyle: "container-hide",
    aboutStyle: "container-hide",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-end",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "",
      2: "",
      3: "",
    },
  },
  3: {
    infoHide: true,
    aboutHide: false,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-left",
    infoStyle: "container-hide",
    aboutStyle: "about-us-container-padding",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-end",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "cloud1-blue-1",
      2: "cloud2-blue-1",
      3: "cloud3-blue-1",
    },
  },
  4: {
    infoHide: true,
    aboutHide: false,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-left",
    infoStyle: "container-hide",
    aboutStyle: "about-us-container-padding",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-end",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "cloud1-blue-1",
      2: "cloud2-blue-1",
      3: "cloud3-blue-1",
    },
  },
  5: {
    infoHide: true,
    aboutHide: false,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-left",
    infoStyle: "container-hide",
    aboutStyle: "about-us-container-padding",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-end",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "cloud1-blue-1",
      2: "cloud2-blue-1",
      3: "cloud3-blue-1",
    },
  },
  6: {
    infoHide: true,
    aboutHide: false,
    servicesHide: true,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-left",
    infoStyle: "container-hide",
    aboutStyle: "about-us-container-padding",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-end",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "cloud1-blue-1",
      2: "cloud2-blue-1",
      3: "cloud3-blue-1",
    },
  },
  7: {
    infoHide: true,
    aboutHide: false,
    servicesHide: false,
    servicesAirHide: true,
    airplaneStyle: "airplane-to-left",
    infoStyle: "container-hide",
    aboutStyle: "about-us-container-padding",
    backgroundBlue: true,
    backgroundBlueStyle: "background-blue-end",
    backgroundRedStyle: "background-red-start",
    cloudStyle: {
      1: "cloud1-blue-1",
      2: "cloud2-blue-1",
      3: "cloud3-blue-1",
    },
  },
};
