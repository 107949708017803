const scrollAddClass = (findClass: string, addClass: string) => {
  const targetElements = document.querySelectorAll(findClass);

  targetElements.forEach((targetElement) => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(addClass);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(targetElement);
  });
};

export const addClass = (findClass: string, addClass: string) => {
  const targetElements = document.querySelectorAll(findClass);

  targetElements.forEach((targetElement) => {
    targetElement.classList.add(addClass);
  });
};

export const removeClass = (findClass: string, addClass: string) => {
  const targetElements = document.querySelectorAll(findClass);

  targetElements.forEach((targetElement) => {
    targetElement.classList.remove(addClass);
  });
};

export default scrollAddClass;
