// types
import classNames from "classnames";
import type { IconType } from "./icon.types";
import styles from "./icon.module.css";

export const getComputedClassName = (props: IconType) => {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    className,
  } = props;

  const computedClassName = classNames(
    styles.icon,
    white && styles["icon--white"],
    blue && styles["icon--blue"],
    red && styles["icon--red"],
    green && styles["icon--green"],
    orange && styles["icon--orange"],
    orangeMiddle && styles["icon--orange-middle"],
    grayDark && styles["icon--gray-dark"],
    grayLight && styles["icon--gray-light"],
    className
  );

  return computedClassName;
};
