import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 24"
      {...props}
    >
      <path
        fill="#686868"
        fillRule="evenodd"
        d="m15.531 12.041-11.67 11.67L.68 20.528l8.487-8.487L.679 3.554 4.074.371z"
        clipRule="evenodd"
      />
    </svg>
  );
}
const SvgIconArrowRightPagination = React.memo(Icon);
export default SvgIconArrowRightPagination;
