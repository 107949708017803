import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Service from "./pages/Service";
import AboutUs from "./pages/AboutUs";
import Missions from "./pages/Missions";
import Mission from "./pages/Mission";
import Services from "./pages/Services";
import Feedback from "./pages/Feedback";
import Menu from "./components/Menu";
import ScrollToTop from "./utils/ScrollToTop";
import "./styles/App.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop />

      <div className="app">
        <Menu />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service" element={<Service />} />
          <Route path="/missions" element={<Missions />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
