import imageFour from "../../assets/images/services-info/2.png";
import { SERVICES_DATA } from "../../assets/main.constants";

export const SECOND_DATA_SERVICE = {
  title: "OUR SERVICES",
  items: [
    {
      id: 1,
      titleFirst: "Customized Handling",
      titleSecond: "Solutions",
      description:
        "Tailored approaches for each type of special cargo, ensuring proper care throughout the journey.",
    },
    {
      id: 2,
      titleFirst: "Temperature-",
      titleSecond: "Controlled Transport",
      description:
        "Precise climate control for sensitive items such as pharmaceuticals, perishables, and fine art.",
    },
    {
      id: 3,
      titleFirst: "High-Security",
      titleSecond: "Shipments",
      description:
        "Enhanced security measures for valuable goods, including jewelry, currency, and confidential documents.",
    },
    {
      id: 4,
      titleFirst: "Hazardous Materials",
      titleSecond: "Management",
      description:
        "Expert handling of dangerous goods in compliance with IATA and other regulatory standards.",
    },
    {
      id: 5,
      titleFirst: "Live Animal",
      titleSecond: "Transport",
      description:
        "Specialized care for pets and livestock, adhering to IATA Live Animals Regulations.",
    },
    {
      id: 6,
      titleFirst: "Time-Critical",
      titleSecond: "Shipments",
      description:
        "Expedited services for urgent deliveries, including medical supplies and spare parts.",
    },
  ],
};

export const THIRD_DATA_SERVICE = {
  title: "WHY CHOOSE AIR ALLIANCE US?",
  items: [
    {
      id: 1,
      title: "Expertise",
      description:
        "Our team has decades of proven experience in arranging the transportation of a diverse range of cargoes, ensuring your goods are in capable hands.",
    },
    {
      id: 2,
      title: "Global Network",
      description:
        "Access to a wide array of specialized aircraft and equipment through our extensive partner network.",
    },
    {
      id: 3,
      title: "Compliance Assurance",
      description:
        "In-depth knowledge of international regulations and documentation requirements.",
    },
    {
      id: 4,
      title: "Cost-Effective Solutions",
      description:
        "Our commission-free model allows us to offer competitive rates without compromising on service quality.",
    },
    {
      id: 5,
      title: "24/7 Support",
      description:
        "Round-the-clock assistance from our dedicated team of experts.",
    },
    {
      id: 6,
      title: "Customized Insurance Options",
      description:
        "Tailored coverage plans to protect your valuable or sensitive shipments.",
    },
    {
      id: 7,
      title: "Transparent Communication",
      description:
        "Clear, honest, and timely updates throughout the shipping process.",
    },
    {
      id: 8,
      title: "",
      description:
        "At Air Alliance US, we turn the complexities of special cargo transportation into seamless, efficient solutions. Let us handle your unique shipping needs with the care and expertise they deserve.",
    },
  ],
};

export const FOURTH_DATA_SERVICE = {
  titleFirst: "Futured",
  titleSecond: "Mission",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  image: imageFour,
};

export const FIFTH_DATA_SERVICE = {
  title: "FEATURED MISSIONS",
  items: [...SERVICES_DATA],
};
