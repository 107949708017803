import cloud1 from "./1.png";
import cloud2 from "./2.png";
import cloud3 from "./3.png";
import cloud4 from "./4.png";
import cloud5 from "./5.png";
import cloud6 from "./6.png";
import cloud7 from "./7.png";
import cloud8 from "./8.png";
import cloud9 from "./9.png";
import cloud10 from "./10.png";
import cloud11 from "./11.png";
import cloud12 from "./12.png";
import cloud13 from "./13.png";
import cloud14 from "./14.png";
import cloud15 from "./15.png";
import cloud16 from "./16.png";
import cloud17 from "./17.png";
import cloud18 from "./18.png";
import cloud19 from "./19.png";
import cloud20 from "./20.png";
import cloud21 from "./21.png";
import cloud22 from "./22.png";
import cloud23 from "./23.png";
import cloud24 from "./24.png";
import cloud25 from "./25.png";
import cloud26 from "./26.png";
import cloud27 from "./27.png";
import cloud28 from "./28.png";
import cloud29 from "./29.png";
import cloud30 from "./30.png";

const clouds = {
  cloud1,
  cloud2,
  cloud3,
  cloud4,
  cloud5,
  cloud6,
  cloud7,
  cloud8,
  cloud9,
  cloud10,
  cloud11,
  cloud12,
  cloud13,
  cloud14,
  cloud15,
  cloud16,
  cloud17,
  cloud18,
  cloud19,
  cloud20,
  cloud21,
  cloud22,
  cloud23,
  cloud24,
  cloud25,
  cloud26,
  cloud27,
  cloud28,
  cloud29,
  cloud30,
};

export default clouds;
