import secondImage1 from "../../assets/images/contact/item-1.png";
import secondImage2 from "../../assets/images/contact/item-2.png";
import secondImage3 from "../../assets/images/contact/item-3.png";
import secondImage4 from "../../assets/images/contact/item-4.png";
import secondImage5 from "../../assets/images/contact/item-5.png";
import secondImage6 from "../../assets/images/contact/item-6.png";
import secondImage7 from "../../assets/images/contact/item-7.png";
import secondImage8 from "../../assets/images/contact/item-8.png";
import secondImage9 from "../../assets/images/contact/item-9.png";
import secondImage10 from "../../assets/images/contact/item-10.png";
import secondImage11 from "../../assets/images/contact/item-11.png";
import secondImage12 from "../../assets/images/contact/item-12.png";

// images
import gerb from "../../assets/images/gerb.png";
import compass from "../../assets/images/compass.png";
import { FEATURES_DATA, SERVICES_DATA } from "../../assets/main.constants";

export const FIRST_DATA_CONTACTS = {
  title: "TAILORED AIR FREIGHT SOLUTION FOR EVERY CARGO NEED",
  description:
    "Our innovative commission-free brokerage model on select aircrafts allows us to connect you with premier air freight services, leveraging our extensive network and expertise to provide efficient, cost-effective, and transparent transportation for your cargo. From standard shipments to specialized handling, we're dedicated to optimizing your supply chain and reducing your logistics costs",
};

export const SECOND_DATA_CONTACTS = [...FEATURES_DATA];

export const THIRD_DATA_CONTACTS = {
  title: "need more information?",
  description:
    "Tell us your requirements and one of our charter experts will send you a quote.",
  to: "/",
  items: [
    {
      id: 31,
      icon: null,
      image: null,
      url: null,
      title: "№1",
      subTitle: "Price leadership in the industry",
    },
    {
      id: 32,
      image: null,
      title: "24/7",
      subTitle:
        "Uninterrupted 24/7 customer support, ensuring we’re always available for you.",
    },
    {
      id: 33,
      image: null,
      title: "+5,000",
      subTitle: "A thriving network of over 5,000 pleased customers",
    },
    {
      id: 34,
      image: null,
      title: "+30",
      subTitle: "More than three decades, 30+ years, of industry experience",
    },
    {
      id: 35,
      image: gerb,
      title: null,
      subTitle: null,
    },
    {
      id: 36,
      image: compass,
      title: null,
      subTitle: null,
    },
  ],
};
export const FIFTH_DATA_SERVICE = {
  title: "FEATURED MISSIONS",
  items: [...SERVICES_DATA],
};
