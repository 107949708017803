import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// components
import CloudsCustom from "../../components/CloudsCustom";
import Footer from "../../components/Footer";
import FirstInputs from "./FirstInputs";
import SecondInputs from "./SecondInputs";
import ThirdInputs from "./ThirdInputs";

// constants
import { DEFAULT_VALUES_INPUTS } from "./constants";

// styles
import styles from "./styles.module.css";

const Feedback: FunctionComponent = () => {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [values, setValues] = useState(DEFAULT_VALUES_INPUTS);

  const onSubmit = async () => {
    const data = {
      ...values[1],
      ...values[2],
      ...values[3],
    };
    try {
      const response = await fetch("/api/sendMail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        alert("Your request has been sent");
        setValues(DEFAULT_VALUES_INPUTS);
        setStep(1);
      } else {
        alert("Error while sending the request");
        setStep(5);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error while sending the request");
    }
  };

  const inputs = useMemo(() => {
    if (step === 1)
      return (
        <div className={styles.containerInputsFirst}>
          <FirstInputs
            values={values[1]}
            onNext={(firstValues) => {
              setValues({ ...values, 1: firstValues });
              setStep(2);
            }}
          />
        </div>
      );
    if (step === 2)
      return (
        <div className={styles.containerInputsSecond}>
          <SecondInputs
            values={values[2]}
            onNext={(secondValues) => {
              setValues({ ...values, 2: secondValues });
              setStep(3);
            }}
            onBack={() => setStep(1)}
          />
        </div>
      );
    if (step === 3)
      return (
        <ThirdInputs
          values={values[3]}
          onNext={(thirdValues) => {
            setValues({ ...values, 3: thirdValues });
            setStep(4);
          }}
          onBack={() => setStep(2)}
        />
      );
    if (step === 4)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: "30px",
            }}
          >
            Sending...
          </h1>
        </div>
      );
    if (step === 5)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: "30px",
              marginTop: "50px",
            }}
          >
            Sorry! Error while sending the request
          </h1>
        </div>
      );
  }, [step, values]);

  useEffect(() => {
    if (step === 4) {
      onSubmit();
    }
  }, [step]);

  useEffect(() => {
    if (location.state && location.state.valuesFirst && location.state.step) {
      setValues({
        ...values,
        1: location.state.valuesFirst,
      });
      setStep(location.state.step);
    }
  }, [location]);

  return (
    <div className="services">
      <div
        className={`background`}
        style={{
          background: `linear-gradient(180deg, #72A4CB 130%, #A7BDCB 160%)`,
        }}
      >
        <CloudsCustom type="feedback" />
        <div className={styles.first}>
          <div className={styles.body}>
            <div>
              <h1 className={styles.title}>REQUEST A FAST QUOTE</h1>
              <p className={styles.subTitle}>
                Tell us your requirements and one of our charter experts will
                provide you with a no obligation quote as soon as possible.
              </p>
            </div>
            {inputs}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Feedback;
