import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";

// components
import ButtonBlue from "../../components/ButtonBlue";
import InputFieldIcon from "../Services/utils/InputFieldIcon";

// styles
import styles from "./styles.module.css";
import classNames from "classnames";

type ThirdInputsProps = {
  values: {
    name: string;
    company: string;
    country: string;
    state: string;
    email: string;
    phone: string;
    mobile: string;
  };
  onNext: (values: {
    name: string;
    company: string;
    country: string;
    state: string;
    email: string;
    phone: string;
    mobile: string;
  }) => void;
  onBack: () => void;
};

const ThirdInputs: FunctionComponent<ThirdInputsProps> = ({
  values,
  onNext,
  onBack,
}) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: values,
  });

  const onSubmit = async (data: {
    name: string;
    company: string;
    country: string;
    state: string;
    email: string;
    phone: string;
    mobile: string;
  }) => {
    onNext(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formThird}>
      <div className={styles.twoInputs}>
        <InputFieldIcon
          name="name"
          control={control}
          label="Name"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
        />
        <InputFieldIcon
          name="email"
          control={control}
          label="Email"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
        />
      </div>
      <div className={styles.twoInputs}>
        <InputFieldIcon
          name="company"
          control={control}
          label="Company"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
        />
        <InputFieldIcon
          name="phone"
          control={control}
          label="Phone"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
          typeNumber
        />
      </div>
      <div className={styles.twoInputs}>
        <InputFieldIcon
          name="country"
          control={control}
          label="Country"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
        />
        <InputFieldIcon
          name="mobile"
          control={control}
          label="Mobile"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
          typeNumber
        />
      </div>
      <div className={styles.twoInputs}>
        <InputFieldIcon
          name="state"
          control={control}
          label="State"
          errors={errors}
          rules={{ required: true }}
          stylesInput={styles.input}
        />
        <div className={styles.buttons}>
          <ButtonBlue
            title="BACK"
            styleContainer={styles.containerButton}
            styleButton={classNames([styles.button, styles.buttonBack])}
            onClick={onBack}
          />
          <ButtonBlue
            title="NEXT"
            styleContainer={classNames([
              styles.containerButton,
              styles.buttonNextContainer,
            ])}
            styleButton={classNames([styles.button, styles.buttonNext])}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default ThirdInputs;
