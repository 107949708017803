import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 24"
      {...props}
    >
      <path
        fill="#535353"
        fillOpacity={0.5}
        fillRule="evenodd"
        d="M.787 12.05 12.457.38l3.183 3.182-8.487 8.487 8.487 8.488-3.395 3.182z"
        clipRule="evenodd"
      />
    </svg>
  );
}
const SvgIconArrowLeftPagination = React.memo(Icon);
export default SvgIconArrowLeftPagination;
