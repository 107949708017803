import React, { FunctionComponent, useMemo, useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

// components
import PaginationMobile from "../PaginationMobile";

// icons
import { IconArrowRight } from "../../icons/components";

// styles
import styles from "./styles.module.css";
import { SECOND_DATA_CONTACTS } from "../../pages/Missions/constants";

type FeaturedComponentProps = {
  title: string;
};

const FeaturedComponent: FunctionComponent<FeaturedComponentProps> = ({
  title,
}) => {
  const navigation = useNavigate();
  const [selectIndex, setSelectIndex] = useState(0);

  const items = useMemo(() => SECOND_DATA_CONTACTS.slice(0, 4), []);

  const onToFeatures = () => {
    navigation("/missions");
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.containerTitle} onClick={onToFeatures}>
          <h1 className={styles.title}>{title}</h1>
          <IconArrowRight
            width="60px"
            fill="transparent"
            style={{
              fill: "transparent",
            }}
          />
        </div>
        <div className={styles.items}>
          {items.map((service, index) => (
            <div
              key={index}
              className={classNames([
                styles.item,
                selectIndex !== index && styles.itemHidden,
              ])}
            >
              <div>
                {service.image && (
                  <img
                    src={service.image}
                    width={90}
                    alt={`item-${index}`}
                    className={styles.image}
                  />
                )}
                <div className={styles.titles}>
                  <h1 className={styles.title}>{service.title}</h1>
                  <p className={styles.subTitle}>{service.description}</p>
                </div>
              </div>
              <div className={styles.containerButton}>
                <Link
                  to={`/mission?missionId=${service.id}`}
                  className={styles.itemLink}
                >
                  READ MORE
                </Link>
              </div>
            </div>
          ))}
        </div>
        <PaginationMobile
          indexShow={selectIndex}
          setIndexShow={setSelectIndex}
          lastIndex={items.length - 1}
        />
        <div className={styles.containerLinkDown} onClick={onToFeatures}>
          <p className={classNames([styles.itemLink, styles.itemLinkDown])}>
            View All Missions
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeaturedComponent;
