import item1 from "./images/home/7-item-1.png";
import item2 from "./images/home/7-item-2.png";
import item3 from "./images/home/7-item-3.png";
import item4 from "./images/home/7-item-4.png";
import secondImage1 from "./images/contact/item-1.png";
import secondImage2 from "./images/contact/item-2.png";
import secondImage3 from "./images/contact/item-3.png";
import secondImage4 from "./images/contact/item-4.png";
import secondImage5 from "./images/contact/item-5.png";
import secondImage6 from "./images/contact/item-6.png";
import secondImage7 from "./images/contact/item-7.png";
import secondImage8 from "./images/contact/item-8.png";
import secondImage9 from "./images/contact/item-9.png";
import secondImage10 from "./images/contact/item-10.png";
import secondImage11 from "./images/contact/item-11.png";
import secondImage12 from "./images/contact/item-12.png";
import {
  IconCargoTransport,
  IconEquipment,
  IconHeavyCargo,
  IconPharmaceuticals,
} from "../icons/components";

export const SERVICES_DATA = [
  {
    id: 51,
    image: item1,
    url: "/",
    title: "Emergency Medical Supply Delivery",
    subTitle:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
  },
  {
    id: 52,
    image: item2,
    url: "/",
    title: "Emergency Medical Supply Delivery",
    subTitle:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
  },
  {
    id: 53,
    image: item3,
    url: "/",
    title: "Emergency Medical Supply Delivery",
    subTitle:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
  },
  {
    id: 54,
    image: item4,
    url: "/",
    title: "Emergency Medical Supply Delivery",
    subTitle:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
  },
];

export const FEATURES_DATA = [
  {
    id: 3,
    title: "Special Cargo",
    littleTitle:
      "Special cargo encompasses a wide range of goods that require unique handling, specialized equipment, or specific environmental conditions during transportation.",
    icon: IconHeavyCargo,
    description:
      "Special cargo encompasses a wide range of goods that require unique handling, specialized equipment, or specific environmental conditions during transportation. These items often have high value, are fragile, time-sensitive, or subject to strict regulations. We understand the complexities involved in shipping special cargo and have developed expertise in managing these challenging shipments.",
    image: secondImage3,
    offers: [
      {
        id: 31,
        title: "Customized Handling Solutions",
        description:
          "Tailored approaches for each type of special cargo, ensuring proper care throughout the journey.",
      },
      {
        id: 32,
        title: "Temperature-Controlled Transport",
        description:
          "Precise climate control for sensitive items such as pharmaceuticals, perishables, and fine art.",
      },
      {
        id: 33,
        title: "High-Security Shipments",
        description:
          "Enhanced security measures for valuable goods, including jewelry, currency, and confidential documents.",
      },
      {
        id: 34,
        title: "Hazardous Materials Management",
        description:
          "Expert handling of dangerous goods in compliance with IATA and other regulatory standards.",
      },
      {
        id: 35,
        title: "Live Animal Transport",
        description:
          "Specialized care for pets and livestock, adhering to IATA Live Animals Regulations.",
      },
      {
        id: 36,
        title: "Time-Critical Shipments",
        description:
          "Expedited services for urgent deliveries, including medical supplies and spare parts.",
      },
    ],
    chooses: [
      {
        id: 31,
        title: "Expertise",
        description:
          "Our team has decades of proven experience in arranging the transportation of a diverse range of cargoes, ensuring your goods are in capable hands.",
      },
      {
        id: 32,
        title: "Global Network",
        description:
          "Access to a wide array of specialized aircraft and equipment through our extensive partner network.",
      },
      {
        id: 33,
        title: "Compliance Assurance",
        description:
          "In-depth knowledge of international regulations and documentation requirements.",
      },
      {
        id: 34,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates without compromising on service quality.",
      },
      {
        id: 35,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our dedicated team of experts.",
      },
      {
        id: 36,
        title: "Customized Insurance Options",
        description:
          "Tailored coverage plans to protect your valuable or sensitive shipments.",
      },
      {
        id: 37,
        title: "Transparent Communication",
        description:
          "Clear, honest, and timely updates throughout the shipping process.",
      },
      {
        id: 38,
        title: null,
        description:
          "At Air Alliance US, we turn the complexities of special cargo transportation into seamless, efficient solutions. Let us handle your unique shipping needs with the care and expertise they deserve.",
      },
    ],
  },
  {
    id: 2,
    title: "Oversized Cargo",
    littleTitle:
      "Oversized cargo presents unique challenges in air freight logistics due to its non-standard dimensions or shape.",
    icon: IconCargoTransport,
    description:
      "Oversized cargo presents unique challenges in air freight logistics due to its non-standard dimensions or shape. These items often exceed the capacity of regular shipping containers and require specialized handling and transportation solutions. At Air Alliance US, we excel in managing the complexities of oversized cargo shipments, by leveraging our extensive partner network, ensuring safe and efficient delivery of your large-scale items.",
    image: secondImage2,
    offers: [
      {
        id: 21,
        title: "Customized Transport Planning",
        description:
          "Tailored logistics strategies for each oversized shipment, considering dimensions, weight, and destination requirements.",
      },
      {
        id: 22,
        title: "Specialized Aircraft Selection",
        description:
          "Access to a range of aircraft capable of accommodating oversized cargo, including freighters with nose-loading capabilities.",
      },
      {
        id: 23,
        title: "Heavy-Lift Solutions",
        description:
          "Expert handling of exceptionally heavy items using specialized loading equipment and techniques.",
      },
      {
        id: 24,
        title: "Permit and Documentation Management",
        description:
          "Assistance with obtaining necessary permits and completing required documentation for oversized shipments.",
      },
      {
        id: 25,
        title: "Route Optimization",
        description:
          "Careful planning of flight routes to accommodate height, weight, and airport restrictions for oversized cargo.",
      },
      {
        id: 26,
        title: "Project Cargo Handling",
        description:
          "Comprehensive logistics solutions for large-scale projects involving multiple oversized shipments.",
      },
    ],
    chooses: [
      {
        id: 21,
        title: "Extensive Experience",
        description:
          "Our team has a proven track record in successfully managing complex oversized cargo shipments across various industries.",
      },
      {
        id: 22,
        title: "Global Network",
        description:
          "Partnerships with specialized carriers and freight forwarders worldwide, ensuring global reach for your oversized items.",
      },
      {
        id: 23,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for oversized cargo without compromising on service quality.",
      },
      {
        id: 24,
        title: "Technical Expertise",
        description:
          "In-depth knowledge of aircraft capabilities, loading techniques, and regulations specific to oversized cargo.",
      },
      {
        id: 25,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our dedicated oversized cargo specialists.",
      },
      {
        id: 26,
        title: "Customized Insurance Options",
        description:
          "Tailored coverage plans to protect your valuable oversized shipments.",
      },
      {
        id: 27,
        title: "Transparent Communication",
        description:
          "Clear and timely updates throughout the shipping process, keeping you informed at every stage.",
      },
      {
        id: 28,
        title: null,
        description:
          "At Air Alliance US, we transform the challenges of oversized cargo transportation into opportunities for efficient and reliable shipping solutions. Let us handle your large-scale logistics needs with precision and expertise.",
      },
    ],
  },
  {
    id: 1,
    title: "Heavy Cargo",
    littleTitle:
      "Heavy cargo shipments present unique challenges in air freight logistics due to their exceptional weight, often exceeding standard load capacities.",
    icon: IconEquipment,
    description:
      "Heavy cargo shipments present unique challenges in air freight logistics due to their exceptional weight, often exceeding standard load capacities. These shipments require specialized equipment, meticulous planning, and expert handling to ensure safe and efficient transportation. At Air Alliance US, we have the expertise and resources to manage even the most demanding heavy cargo logistics.",
    image: secondImage1,
    offers: [
      {
        id: 11,
        title: "Specialized Aircraft Selection",
        description:
          "Access to a fleet of aircraft capable of handling heavy loads, including freighters with high maximum payloads.",
      },
      {
        id: 12,
        title: "Custom Load Planning",
        description:
          "Detailed weight distribution and balance calculations to ensure safe and compliant loading.",
      },
      {
        id: 13,
        title: "Ground Handling Solutions",
        description:
          "Coordination of specialized ground equipment for loading and unloading heavy cargo.",
      },
      {
        id: 14,
        title: "Permit Management",
        description:
          "Assistance in obtaining necessary permits for overweight shipments across various jurisdictions.",
      },
      {
        id: 15,
        title: "Route Optimization",
        description:
          "Strategic planning of flight routes considering weight restrictions at airports and on specific aircraft.",
      },
      {
        id: 16,
        title: "Project Cargo Expertise",
        description:
          "Comprehensive logistics solutions for large-scale projects involving multiple heavy shipments.",
      },
    ],
    chooses: [
      {
        id: 11,
        title: "Proven Expertise",
        description:
          "Extensive experience in managing complex heavy cargo shipments across various industries.",
      },
      {
        id: 12,
        title: "Global Network",
        description:
          "Strong partnerships with specialized carriers and equipment providers worldwide.",
      },
      {
        id: 13,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for heavy cargo transportation without compromising on service quality.",
      },
      {
        id: 14,
        title: "Technical Knowledge",
        description:
          "In-depth understanding of weight limitations, aircraft capabilities, and regulatory requirements specific to heavy cargo.",
      },
      {
        id: 15,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our dedicated heavy cargo specialists.",
      },
      {
        id: 16,
        title: "Customized Insurance",
        description:
          "Tailored coverage options to protect your valuable heavy shipments.",
      },
      {
        id: 17,
        title: "Safety Focus",
        description:
          "Rigorous adherence to safety protocols and best practices in heavy cargo management.",
      },
      {
        id: 18,
        title: "Regulatory Compliance",
        description:
          "Thorough understanding and strict adherence to international and local regulations governing heavy cargo transport.",
      },
      {
        id: 19,
        title: null,
        description:
          "At Air Alliance US, we turn the complexities of heavy cargo transportation into streamlined, efficient logistics solutions. Let us shoulder the weight of your heavy cargo needs with our expertise and dedicated service.",
      },
    ],
  },
  {
    id: 9,
    title: "Expensive Cargo",
    littleTitle:
      "Expensive cargo encompasses high-value items that require exceptional care, security, and attention to detail throughout the transportation process.",
    icon: IconPharmaceuticals,
    description:
      "Expensive cargo encompasses high-value items that require exceptional care, security, and attention to detail throughout the transportation process. These shipments often include luxury goods, precious metals, fine art, advanced technology, and other items of significant monetary or intrinsic value. At Air Alliance US, we understand the unique requirements of expensive cargo and offer specialized solutions to ensure its safe and secure delivery.",
    image: secondImage9,
    offers: [
      {
        id: 91,
        title: "Enhanced Security Measures",
        description:
          "Implementation of advanced security protocols tailored to the specific needs of high-value shipments.",
      },
      {
        id: 92,
        title: "Discrete Handling",
        description:
          "Confidential processing and transportation to maintain privacy and reduce visibility of valuable items.",
      },
      {
        id: 93,
        title: "Specialized Packaging",
        description:
          "Custom packaging solutions designed to protect expensive items from damage during transit.",
      },
      {
        id: 94,
        title: "Climate-Controlled Transport",
        description:
          "Temperature and humidity-controlled environments for sensitive valuable goods.",
      },
      {
        id: 95,
        title: "Hand-Carry Services",
        description:
          "Personal courier options for extremely high-value or time-critical items.",
      },
      {
        id: 96,
        title: "Insurance Coordination",
        description:
          "Assistance in arranging comprehensive insurance coverage for high-value shipments.",
      },
      {
        id: 97,
        title: "Customs Expertise",
        description:
          "Specialized knowledge in customs procedures for valuable goods, including ATA Carnet processing.",
      },
    ],
    chooses: [
      {
        id: 91,
        title: "Proven Track Record",
        description:
          "Extensive experience in handling and transporting a wide range of expensive and high-value cargo.",
      },
      {
        id: 92,
        title: "Global Secure Network",
        description:
          "Partnerships with trusted, security-vetted agents and carriers worldwide.",
      },
      {
        id: 93,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for expensive cargo transportation without compromising on security or service quality.",
      },
      {
        id: 94,
        title: "Dedicated Specialist Team",
        description:
          "Highly trained professionals specializing in the logistics of high-value and sensitive items.",
      },
      {
        id: 95,
        title: "24/7 Monitoring and Support",
        description:
          "Round-the-clock surveillance and assistance for your valuable shipments.",
      },
      {
        id: 96,
        title: "Customized Risk Assessment",
        description:
          "Thorough evaluation of potential risks and implementation of mitigation strategies for each shipment.",
      },
      {
        id: 97,
        title: "Transparent Communication",
        description:
          "Regular, detailed updates throughout the shipping process, ensuring complete visibility.",
      },
      {
        id: 98,
        title: "Discretion and Confidentiality",
        description:
          "Strict adherence to privacy protocols to protect the nature and details of valuable shipments.",
      },
      {
        id: 99,
        title: null,
        description:
          "At Air Alliance US, we combine our logistics expertise with stringent security measures to provide unparalleled service for your expensive cargo. Trust us to handle your high-value shipments with the utmost care, security, and efficiency",
      },
    ],
  },
  {
    id: 4,
    title: "Fragile Cargo",
    littleTitle:
      "Fragile cargo requires exceptional care and precision throughout the transportation process to ensure its intact arrival at the destination.",
    icon: IconPharmaceuticals,
    description:
      "Fragile cargo requires exceptional care and precision throughout the transportation process to ensure its intact arrival at the destination. These shipments may include delicate electronics, glassware, scientific instruments, artwork, or any items susceptible to damage from impacts, vibrations, or environmental changes. At Air Alliance US, we specialize in handling fragile goods with the utmost care and attention to detail.",
    image: secondImage4,
    offers: [
      {
        id: 41,
        title: "Custom Packaging Solutions",
        description:
          "Tailored packaging designs using high-quality materials to provide optimal protection for fragile items.",
      },
      {
        id: 42,
        title: "Specialized Handling Procedures",
        description:
          "Implementation of strict protocols for loading, unloading, and transferring fragile cargo to minimize the risk of damage.",
      },
      {
        id: 43,
        title: "Shock-Absorbent Transportation",
        description:
          "Utilization of shock-absorbing materials and equipment to protect items from vibrations during transit.",
      },
      {
        id: 44,
        title: "Climate-Controlled Environments",
        description:
          "Temperature and humidity-controlled storage and transportation options for sensitive fragile goods.",
      },
      {
        id: 45,
        title: "Dedicated Fragile Cargo Teams",
        description:
          "Specially trained personnel to handle delicate items throughout the shipping process.",
      },
      {
        id: 46,
        title: "Careful Route Planning",
        description:
          "Strategic selection of routes and modes of transport to minimize handling and potential risks.",
      },
      {
        id: 47,
        title: "Custom Crating Services",
        description:
          "Design and construction of custom crates for uniquely shaped or extremely delicate items.",
      },
    ],
    chooses: [
      {
        id: 41,
        title: "Expertise in Fragile Goods",
        description:
          "Extensive experience in successfully transporting a wide range of delicate and fragile items.",
      },
      {
        id: 42,
        title: "Global Network of Specialists",
        description:
          "Partnerships with experts in fragile cargo handling across our international network.",
      },
      {
        id: 43,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for fragile cargo transportation without compromising on care or quality.",
      },
      {
        id: 44,
        title: "Customized Risk Assessment",
        description:
          "Thorough evaluation of potential risks and implementation of specific mitigation strategies for each fragile shipment.",
      },
      {
        id: 45,
        title: "Comprehensive Insurance Options",
        description:
          "Tailored insurance coverage to protect your valuable and delicate shipments.",
      },
      {
        id: 46,
        title: "Transparent Communication",
        description:
          "Regular, detailed updates throughout the shipping process, ensuring you're always informed about the status of your fragile cargo.",
      },
      {
        id: 47,
        title: "Innovative Packaging Technology",
        description:
          "Utilization of the latest advancements in protective packaging materials and techniques.",
      },
      {
        id: 48,
        title: "Compliance with Standards",
        description:
          "Adherence to international standards and best practices for handling and transporting fragile goods.",
      },
      {
        id: 49,
        title: "Attention to Detail",
        description:
          "Meticulous planning and execution of every step in the fragile cargo transportation process.",
      },
      {
        id: 50,
        title: null,
        description:
          "At Air Alliance US, we understand that fragile cargo requires more than just careful handling – it demands expertise, precision, and unwavering attention to detail. Trust us to deliver your delicate items safely and securely, treating each fragile shipment with the care it deserves.",
      },
    ],
  },
  {
    id: 5,
    title: "Perishable Cargo",
    littleTitle:
      "Perishable cargo involves time-sensitive goods that require swift transportation and precise environmental control to maintain their quality and freshness.",
    icon: IconCargoTransport,
    description:
      "Perishable cargo involves time-sensitive goods that require swift transportation and precise environmental control to maintain their quality and freshness. These shipments may include fresh produce, flowers, seafood, dairy products, pharmaceuticals, and other items with limited shelf life. At Air Alliance US, we specialize in the rapid and controlled transportation of perishable goods, ensuring they reach their destination in optimal condition.",
    image: secondImage5,
    offers: [
      {
        id: 51,
        title: "Rapid Transit Solutions",
        description:
          "Expedited shipping options to minimize transit times for time-sensitive perishables.",
      },
      {
        id: 52,
        title: "Temperature-Controlled Logistics",
        description:
          "End-to-end cold chain management with precise temperature and humidity control.",
      },
      {
        id: 53,
        title: "Specialized Packaging",
        description:
          "Custom packaging solutions designed to maintain the ideal environment for specific perishable goods.",
      },
      {
        id: 54,
        title: "Priority Handling",
        description:
          "Expedited customs clearance and priority loading/unloading for perishable shipments.",
      },
      {
        id: 55,
        title: "Compliance Management",
        description:
          "Adherence to all regulatory requirements for transporting perishable goods, including health and safety standards.",
      },
      {
        id: 56,
        title: "Flexible Scheduling",
        description:
          "Multiple daily flights on key routes to accommodate urgent perishable shipments.",
      },
      {
        id: 57,
        title: "Last-Mile Refrigerated Transport",
        description:
          "Coordination of temperature-controlled ground transportation for final delivery.",
      },
    ],
    chooses: [
      {
        id: 51,
        title: "Perishables Expertise",
        description:
          "Extensive experience in managing a wide range of perishable cargo types and their specific requirements.",
      },
      {
        id: 52,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for perishable cargo transportation without compromising on speed or quality.",
      },
      {
        id: 53,
        title: "Dedicated Perishables Team",
        description:
          "Specialists trained in handling various types of perishable goods and their unique needs.",
      },
      {
        id: 54,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our perishable cargo experts to address any concerns or time-sensitive issues.",
      },
      {
        id: 55,
        title: "Customized Insurance Options",
        description:
          "Tailored coverage plans to protect your valuable perishable shipments.",
      },
      {
        id: 56,
        title: "Transparent Communication",
        description:
          "Real-time updates and alerts throughout the shipping process, ensuring full visibility of your perishable cargo.",
      },
      {
        id: 57,
        title: "Contingency Planning",
        description:
          "Robust backup plans and alternative routing options to mitigate risks in perishable cargo transportation.",
      },
      {
        id: 58,
        title: null,
        description:
          "At Air Alliance US, we understand that every minute counts when it comes to perishable cargo. Our tailored solutions and expertise ensure that your time-sensitive goods arrive fresh, safe, and on time. Trust us to preserve the quality of your perishable shipments throughout their journey.",
      },
    ],
  },
  {
    id: 6,
    title: "Dangerous Goods",
    littleTitle:
      "Dangerous goods, also known as hazardous materials, are substances or items that pose potential risks to health, safety, property, or the environment during transportation.",
    icon: IconCargoTransport,
    description:
      "Dangerous goods, also known as hazardous materials, are substances or items that pose potential risks to health, safety, property, or the environment during transportation. These shipments require specialized handling, packaging, and documentation to ensure compliance with strict international regulations. At Air Alliance US, we have the expertise and systems in place to manage the complex requirements of dangerous goods transportation safely and efficiently.",
    image: secondImage6,
    offers: [
      {
        id: 61,
        title: "Comprehensive Classification",
        description:
          "Expert assessment and proper classification of dangerous goods according to IATA DGR and other relevant regulations.",
      },
      {
        id: 62,
        title: "Compliant Packaging",
        description:
          "Provision of and guidance on UN-approved packaging materials suitable for various classes of dangerous goods.",
      },
      {
        id: 63,
        title: "Documentation Management",
        description:
          "Preparation and verification of all required dangerous goods documentation, including declarations and safety data sheets.",
      },
      {
        id: 64,
        title: "Specialized Handling",
        description:
          "Implementation of strict protocols for the safe handling, storage, and transportation of hazardous materials.",
      },
      {
        id: 65,
        title: "Regulatory Compliance",
        description:
          "Ensuring adherence to all applicable national and international regulations governing the transport of dangerous goods.",
      },
      {
        id: 66,
        title: "Emergency Response Planning",
        description:
          "Development and implementation of contingency plans for potential incidents involving dangerous goods.",
      },
    ],
    chooses: [
      {
        id: 61,
        title: "Dangerous Goods Expertise",
        description:
          "Extensive experience in managing a wide range of hazardous materials across various industries.",
      },
      {
        id: 62,
        title: "Global Compliance Network",
        description:
          "Partnerships with certified dangerous goods handlers and facilities worldwide.",
      },
      {
        id: 63,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for dangerous goods transportation without compromising on safety or compliance.",
      },
      {
        id: 64,
        title: "Dedicated Hazmat Team",
        description:
          "Specialists trained in the latest dangerous goods regulations and handling procedures.",
      },
      {
        id: 65,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our dangerous goods experts to address any concerns or emergencies.",
      },
      {
        id: 66,
        title: "Customized Risk Assessment",
        description:
          "Thorough evaluation of potential risks and implementation of specific mitigation strategies for each dangerous goods shipment.",
      },
      {
        id: 67,
        title: "Transparent Communication",
        description:
          "Regular updates and immediate notification of any issues or delays related to your hazardous shipments.",
      },
      {
        id: 68,
        title: "Continuous Training",
        description:
          "Ongoing education and certification of our team to stay ahead of evolving dangerous goods regulations.",
      },
      {
        id: 69,
        title: null,
        description:
          "At Air Alliance US, we understand the critical nature of dangerous goods transportation. Our expertise ensures that your hazardous shipments are handled with the utmost care, in full compliance with all regulations, and with safety as the top priority. Trust us to navigate the complexities of dangerous goods logistics, providing you with peace of mind and reliable service.",
      },
    ],
  },
  {
    id: 7,
    title: "Equipment Transportation",
    littleTitle:
      "Equipment transportation encompasses the logistics of moving a wide range of machinery, tools, and devices, from industrial equipment to specialized instruments.",
    icon: IconCargoTransport,
    description:
      "Equipment transportation encompasses the logistics of moving a wide range of machinery, tools, and devices, from industrial equipment to specialized instruments. This service requires expert handling, custom solutions, and meticulous planning to ensure safe and efficient transport. At Air Alliance US, we specialize in the seamless transportation of equipment, catering to various industries and their unique needs.",
    image: secondImage7,
    offers: [
      {
        id: 71,
        title: "Custom Logistics Planning",
        description:
          "Tailored transportation strategies for each piece of equipment, considering size, weight, and specific handling requirements.",
      },
      {
        id: 72,
        title: "Specialized Packaging",
        description:
          "Custom crating and packaging solutions designed to protect equipment during transit.",
      },
      {
        id: 73,
        title: "Heavy Lift Capabilities",
        description:
          "Access to aircraft and ground equipment capable of handling oversized and heavy machinery.",
      },
      {
        id: 74,
        title: "Disassembly and Reassembly Services",
        description:
          "Coordination of expert technicians for complex equipment that requires dismantling for transport.",
      },
      {
        id: 75,
        title: "Equipment-Specific Handling",
        description:
          "Specialized procedures for sensitive instruments, calibrated machinery, and high-tech equipment.",
      },
      {
        id: 76,
        title: "Documentation Management",
        description:
          "Handling of all necessary paperwork, including carnets, permits, and customs documentation.",
      },
    ],
    chooses: [
      {
        id: 71,
        title: "Industry Expertise",
        description:
          "Extensive experience in transporting a wide range of equipment for various sectors, including manufacturing, healthcare, construction, and technology.",
      },
      {
        id: 72,
        title: "Global Network",
        description:
          "Partnerships with specialized equipment handlers and freight forwarders worldwide, ensuring global reach and local expertise.",
      },
      {
        id: 73,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for equipment transportation without compromising on service quality.",
      },
      {
        id: 74,
        title: "Technical Knowledge",
        description:
          "In-depth understanding of equipment transportation challenges and solutions across different industries.",
      },
      {
        id: 75,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our dedicated equipment transportation specialists.",
      },
      {
        id: 76,
        title: "Customized Insurance Options",
        description:
          "Tailored coverage plans to protect your valuable equipment during transit.",
      },
      {
        id: 77,
        title: "Transparent Communication",
        description:
          "Clear and timely updates throughout the shipping process, keeping you informed at every stage.",
      },
      {
        id: 78,
        title: "Compliance Assurance",
        description:
          "Thorough understanding and adherence to international regulations governing the transport of various types of equipment.",
      },
      {
        id: 79,
        title: null,
        description:
          "At Air Alliance US, we understand that each piece of equipment is crucial to your operations. Our expert team ensures that your machinery, tools, and devices are transported with the utmost care, precision, and efficiency. Trust us to deliver your equipment safely, on time, and ready for immediate use.",
      },
    ],
  },
  {
    id: 8,
    title: "Pharmaceuticals Transportation",
    littleTitle:
      "Pharmaceutical logistics demands the highest standards of care, precision, and compliance to ensure the integrity and efficacy of medicines and medical supplies throughout the transportation process.",
    icon: IconEquipment,
    description:
      "Pharmaceutical logistics demands the highest standards of care, precision, and compliance to ensure the integrity and efficacy of medicines and medical supplies throughout the transportation process. At Air Alliance US, we specialize in the meticulous handling and transportation of pharmaceutical products, adhering to strict regulatory requirements and maintaining optimal conditions to preserve product quality.",
    image: secondImage8,
    offers: [
      {
        id: 81,
        title: "Temperature-Controlled Solutions",
        description:
          "End-to-end cold chain management with precise temperature control for various pharmaceutical requirements (2-8°C, 15-25°C, -20°C, etc.).",
      },
      {
        id: 82,
        title: "Specialized Packaging",
        description:
          "Validated thermal packaging solutions to maintain required temperatures during transit.",
      },
      {
        id: 83,
        title: "Regulatory Compliance",
        description:
          "Assistance with documentation and adherence to international and local pharmaceutical transportation regulations.",
      },
      {
        id: 84,
        title: "Expedited Services",
        description:
          "Priority handling and customs clearance for time-sensitive pharmaceutical shipments.",
      },
      {
        id: 85,
        title: "Security Measures",
        description:
          "Enhanced security protocols to prevent theft, tampering, or counterfeiting of valuable pharmaceuticals.",
      },
    ],
    chooses: [
      {
        id: 81,
        title: "Pharmaceutical Expertise",
        description:
          "Extensive experience in handling a wide range of pharmaceutical products and their specific requirements.",
      },
      {
        id: 82,
        title: "Global Pharma Network",
        description:
          "Partnerships with GDP-certified facilities and carriers worldwide.",
      },
      {
        id: 83,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for pharmaceutical transportation without compromising on quality or compliance.",
      },
      {
        id: 84,
        title: "Dedicated Pharma Team",
        description:
          "Specialists trained in pharmaceutical logistics and regulatory requirements.",
      },
      {
        id: 85,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our pharmaceutical logistics experts.",
      },
      {
        id: 86,
        title: "Risk Management",
        description:
          "Comprehensive risk assessment and mitigation strategies for each pharmaceutical shipment.",
      },
      {
        id: 87,
        title: "Transparent Communication",
        description:
          "Real-time updates and detailed reporting throughout the shipping process.",
      },
      {
        id: 88,
        title: "Flexibility and Scalability",
        description:
          "Ability to handle both small, specialized shipments and large-scale pharmaceutical distribution.",
      },
      {
        id: 89,
        title: null,
        description:
          "At Air Alliance US, we understand the critical nature of pharmaceutical transportation. Our tailored solutions ensure that your medicines and medical supplies are transported with the utmost care, maintaining their efficacy and safety from origin to destination. Trust us to deliver your pharmaceutical products with precision, compliance, and reliability.",
      },
    ],
  },
  {
    id: 10,
    title: "High-Tech Devices Transportation",
    littleTitle:
      "The transportation of high-tech devices requires exceptional care, security, and precision to protect these sensitive and often valuable items.",
    icon: IconEquipment,
    description:
      "The transportation of high-tech devices requires exceptional care, security, and precision to protect these sensitive and often valuable items. From cutting-edge electronics to advanced scientific instruments, Air Alliance US specializes in the safe and efficient transportation of complex technological equipment, ensuring they arrive in perfect condition and ready for immediate use.",
    image: secondImage10,
    offers: [
      {
        id: 101,
        title: "Custom Handling Protocols",
        description:
          "Tailored procedures for each type of high-tech device, considering their specific sensitivities and requirements.",
      },
      {
        id: 102,
        title: "Advanced Packaging Solutions",
        description:
          "State-of-the-art packaging designed to protect against static, shock, vibration, and environmental factors.",
      },
      {
        id: 103,
        title: "Climate-Controlled Transport",
        description:
          "Precise temperature and humidity control to prevent damage to sensitive components.",
      },
      {
        id: 104,
        title: "Enhanced Security Measures",
        description:
          "Rigorous security protocols to safeguard valuable and confidential high-tech equipment.",
      },
      {
        id: 105,
        title: "Calibration Preservation",
        description:
          "Strategies to maintain the calibration of precision instruments during transit.",
      },
      {
        id: 106,
        title: "Technical Expertise",
        description:
          "Collaboration with device manufacturers to ensure proper handling and transportation procedures.",
      },
    ],
    chooses: [
      {
        id: 101,
        title: "High-Tech Expertise",
        description:
          "Extensive experience in transporting a wide range of advanced technological equipment across various industries.",
      },
      {
        id: 102,
        title: "Global Tech Network",
        description:
          "Partnerships with specialized handlers and facilities equipped for high-tech logistics worldwide.",
      },
      {
        id: 103,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for high-tech device transportation without compromising on quality or security.",
      },
      {
        id: 104,
        title: "Dedicated Tech Team",
        description:
          "Specialists trained in handling sophisticated technological equipment and understanding their unique requirements.",
      },
      {
        id: 105,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our high-tech logistics experts to address any concerns or time-sensitive issues.",
      },
      {
        id: 106,
        title: "Customized Insurance Options",
        description:
          "Tailored coverage plans to protect your valuable high-tech shipments.",
      },
      {
        id: 107,
        title: "Innovation in Logistics",
        description:
          "Utilization of the latest technologies in tracking, handling, and protecting high-tech shipments.",
      },
      {
        id: 108,
        title: "Compliance Expertise",
        description:
          "In-depth knowledge of international regulations and export controls related to high-tech equipment.",
      },
      {
        id: 109,
        title: "Flexible Solutions",
        description:
          "Ability to adapt to the unique needs of different high-tech devices, from large industrial equipment to small, sensitive components.",
      },
      {
        id: 110,
        title: null,
        description:
          "At Air Alliance US, we understand that high-tech devices are not just valuable in monetary terms but are often critical to your operations or research. Our expert team ensures that your advanced technological equipment is transported with the utmost care, precision, and security. Trust us to deliver your high-tech devices safely, maintaining their integrity and functionality throughout the journey.",
      },
    ],
  },
  {
    id: 11,
    title: "Diplomatic Cargo Transportation",
    littleTitle:
      "Diplomatic cargo requires the highest levels of security, confidentiality, and specialized handling to ensure the safe and discreet transportation of sensitive materials.",
    icon: IconCargoTransport,
    description:
      "Diplomatic cargo requires the highest levels of security, confidentiality, and specialized handling to ensure the safe and discreet transportation of sensitive materials. At Air Alliance US, we understand the unique requirements of diplomatic shipments and offer tailored solutions that meet the exacting standards of governmental and diplomatic entities.",
    image: secondImage11,
    offers: [
      {
        id: 111,
        title: "Strict Confidentiality",
        description:
          "Rigorous protocols to maintain absolute discretion and privacy throughout the transportation process.",
      },
      {
        id: 112,
        title: "Enhanced Security Measures",
        description:
          "Advanced security systems and procedures to safeguard diplomatic shipments against unauthorized access or interference.",
      },
      {
        id: 113,
        title: "Diplomatic Clearance Assistance",
        description:
          "Expert handling of diplomatic documentation and liaison with relevant authorities for smooth clearance.",
      },
      {
        id: 114,
        title: "Priority Handling",
        description:
          "Expedited processing and dedicated resources to ensure swift transportation of diplomatic cargo.",
      },
      {
        id: 115,
        title: "Secure Chain of Custody",
        description:
          "Unbroken chain of custody with authorized personnel handling the shipment from origin to destination.",
      },
      {
        id: 116,
        title: "Specialized Packaging",
        description:
          "Custom, tamper-evident packaging solutions designed for diplomatic cargo.",
      },
      {
        id: 117,
        title: "Flexible Routing Options",
        description:
          "Ability to accommodate specific routing requirements or last-minute changes for diplomatic missions.",
      },
    ],
    chooses: [
      {
        id: 111,
        title: "Extensive Expertise",
        description:
          "Extensive experience in managing sensitive governmental and diplomatic shipments with the utmost care and professionalism.",
      },
      {
        id: 112,
        title: "Global Network",
        description:
          "Established relationships with diplomatic channels and secure facilities worldwide.",
      },
      {
        id: 113,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for diplomatic cargo transportation without compromising on security or service quality.",
      },
      {
        id: 114,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our diplomatic cargo experts, ensuring immediate response to any situation.",
      },
      {
        id: 115,
        title: "Customized Security Plans",
        description:
          "Tailored security measures designed to meet the specific requirements of each diplomatic shipment.",
      },
      {
        id: 116,
        title: "Transparent yet Secure Communication",
        description:
          "Secure channels for providing updates and information to authorized personnel only.",
      },
      {
        id: 117,
        title: "Compliance Assurance",
        description:
          "Thorough understanding and strict adherence to international laws and conventions governing diplomatic cargo.",
      },
      {
        id: 118,
        title: "Flexibility and Adaptability",
        description:
          "Ability to quickly respond to changing diplomatic needs or urgent shipment requirements.",
      },
      {
        id: 119,
        title: null,
        description:
          "At Air Alliance US, we recognize the critical importance and sensitive nature of diplomatic cargo. Our specialized services ensure that your diplomatic shipments are handled with the highest levels of security, confidentiality, and efficiency. Trust us to manage your diplomatic logistics with the utmost professionalism and reliability.",
      },
    ],
  },
  {
    id: 12,
    title: "Live Animal Transportation",
    littleTitle:
      "Transporting live animals requires exceptional care, expertise, and adherence to strict regulations to ensure the safety, comfort, and well-being of the animals throughout their journey.",
    icon: IconCargoTransport,
    description:
      "Transporting live animals requires exceptional care, expertise, and adherence to strict regulations to ensure the safety, comfort, and well-being of the animals throughout their journey. At Air Alliance US, we specialize in providing humane, efficient, and compliant transportation solutions for a wide range of live animals, from pets to livestock and exotic species.",
    image: secondImage12,
    offers: [
      {
        id: 121,
        title: "Customized Transport Plans",
        description:
          "Tailored logistics strategies for each animal, considering species-specific needs, size, and travel duration.",
      },
      {
        id: 122,
        title: "IATA Live Animals Regulations (LAR) Compliance",
        description:
          "Strict adherence to international standards for the safe transport of live animals by air.",
      },
      {
        id: 123,
        title: "Specialized Animal Containers",
        description:
          "Access to a wide range of IATA-approved kennels and containers suitable for various species.",
      },
      {
        id: 124,
        title: "Climate-Controlled Transport",
        description:
          "Temperature and humidity control to ensure optimal conditions for animal comfort and safety.",
      },
      {
        id: 125,
        title: "Expedited Customs Clearance",
        description:
          "Priority handling to minimize transit times and stress for animals.",
      },
      {
        id: 126,
        title: "Animal Welfare Monitoring",
        description:
          "Regular checks and updates on animal well-being throughout the journey.",
      },
    ],
    chooses: [
      {
        id: 121,
        title: "Animal Transport Expertise",
        description:
          "Extensive experience in handling a diverse range of animals and understanding their unique transportation requirements.",
      },
      {
        id: 122,
        title: "Cost-Effective Solutions",
        description:
          "Our commission-free model allows us to offer competitive rates for live animal transportation without compromising on care or compliance.",
      },
      {
        id: 123,
        title: "Dedicated Animal Welfare Team",
        description:
          "Specialists trained in animal handling and familiar with species-specific needs.",
      },
      {
        id: 124,
        title: "24/7 Support",
        description:
          "Round-the-clock assistance from our live animal transport experts to address any concerns during transit.",
      },
      {
        id: 125,
        title: "Transparent Communication",
        description:
          "Regular updates on the status and well-being of animals throughout their journey.",
      },
      {
        id: 126,
        title: "Regulatory Compliance",
        description:
          "In-depth knowledge of international and local regulations governing live animal transportation.",
      },
      {
        id: 127,
        title: null,
        description:
          "At Air Alliance US, we understand that transporting live animals is a significant responsibility. Our expert team ensures that each animal, whether a beloved pet or valuable livestock, receives the highest standard of care and attention throughout their journey. Trust us to provide safe, comfortable, and compliant transportation for your live animal shipments.",
      },
    ],
  },
];
