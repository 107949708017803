import {
  IconCall,
  IconCallOut,
  IconMail,
  IconPlane,
} from "../../icons/components";
import { IconType } from "../../icons/icon.types";

type ButtonType = {
  id: number;
  title: string;
  Icon: React.MemoExoticComponent<
    (originalProps: IconType) => React.JSX.Element
  > | null;
  to: string;
};

export const BUTTONS_FOOTER: ButtonType[] = [
  {
    id: 1,
    title: "Call Us",
    Icon: IconCall,
    to: "tel:+13065055857",
  },
  {
    id: 2,
    title: "Email Us",
    Icon: IconMail,
    to: "mailto:sales@airalliance.us",
  },
  {
    id: 3,
    title: "Call Back",
    Icon: IconCallOut,
    to: "tel:+13065055857",
  },
  {
    id: 4,
    title: "Inquire Now",
    Icon: IconPlane,
    to: "/feedback",
  },
];
