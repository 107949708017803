import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

// components
import Button from "../Button";

// icons
import iconLogo from "../../assets/svg/logo.svg";
import iconPhone from "../../assets/svg/phone.svg";
import iconMail from "../../assets/svg/mail.svg";
import iconInstagram from "../../assets/svg/instagram.svg";
import iconFacebook from "../../assets/svg/facebook.svg";

// constants
import { PATH_SCREENS } from "../Menu/pages";
import { BUTTONS_FOOTER } from "./constants";

// styles
import styles from "./styles.module.css";

const Footer: FunctionComponent = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const phone = "+13065055857";
  const email = "sales@airalliance.us";
  const [isFixed, setIsFixed] = useState(true);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef?.current) {
        const targetPosition = targetRef.current?.offsetTop - 840;
        const windowPosition = window.scrollY;
        setIsFixed(windowPosition < targetPosition);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [targetRef]);

  const toFeedback = () => {
    navigation("/feedback");
  };

  const items = {
    info: (
      <div>
        <img src={iconLogo} width={100} alt={"logo"} />

        <div className={styles.footerLine} />

        <div className={styles.text}>Worldwide air cargo transportation</div>
      </div>
    ),
    menu: (
      <div>
        <div className={styles.title}>Pages</div>

        <div className={styles.footerLine} />

        <div className={styles.footerMenu}>
          <ul>
            {PATH_SCREENS?.map((item, index) => (
              <li key={`footer-menu-${index}`}>
                <Link to={item?.to}>{item?.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ),
    contacts: (
      <div>
        <div className={styles.title}>CONTACT US</div>

        <div className={styles.footerLine} />

        <div className={styles.contactUsItem}>
          <img src={iconPhone} alt={"phone"} />
          <a href={`tel:${phone}`}>{phone}</a>
        </div>

        <div className={styles.contactUsItem}>
          <img src={iconMail} alt={"mail"} />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    ),
    follow: (
      <div>
        <div className={styles.title}>FOLLOW US</div>

        <div className={styles.footerLine} />

        <div className={styles.socials}>
          <a href={"/"}>
            <img src={iconInstagram} width={55} alt={"instagram"} />
          </a>
          <a href={"/"}>
            <img src={iconFacebook} width={55} alt={"facebook"} />
          </a>
        </div>
      </div>
    ),
  };

  return (
    <div
      id="footer"
      className={styles.container}
      style={{
        background: `linear-gradient(180deg, #72A4CB ${70}%, #A7BDCB ${160}%)`,
      }}
      ref={targetRef}
    >
      <div
        className={classNames([styles.buttons, isFixed && styles.buttonsFixed])}
      >
        {BUTTONS_FOOTER.map((item) => (
          <Button
            key={item.id}
            title={item.title}
            onClick={() => {}}
            target={item.id !== 4 ? "_blank" : "_self"}
            toLink={item.to}
            Icon={
              item.Icon ? (
                <item.Icon
                  width="20px"
                  style={{
                    fill: "transparent",
                  }}
                />
              ) : null
            }
            className={styles.button}
          />
        ))}
      </div>
      <div className={styles.footer}>
        <div className={classNames([styles.item, styles.logo])}>
          {items.info}
        </div>

        <div className={classNames([styles.item, styles.pages])}>
          {items.menu}
        </div>

        <div
          className={classNames([
            styles.item,
            styles.contactUs,
            styles.contacts,
          ])}
        >
          {items.contacts}
          {items.follow}
        </div>

        <div className={classNames([styles.item, styles.follow])}>
          {items.follow}
        </div>
      </div>
    </div>
  );
};

export default Footer;
