import React, { FunctionComponent } from "react";
import classNames from "classnames";

import styles from "./styles.module.css";

type ButtonBlueProps = {
  title: string;
  onClick?: () => void;
  styleContainer?: string;
  styleButton?: string;
  type?: "button" | "submit" | "reset";
};

const ButtonBlue: FunctionComponent<ButtonBlueProps> = ({
  title,
  onClick,
  styleContainer,
  styleButton,
  type,
}) => {
  return (
    <div
      className={classNames([styles.containerButton, styleContainer])}
      onClick={onClick}
    >
      <button className={classNames([styles.button, styleButton])} type={type}>
        <p>{title}</p>
      </button>
    </div>
  );
};

export default ButtonBlue;
