export const PATH_SCREENS = [
  {
    title: "About us",
    to: "/",
  },
  {
    title: "Services",
    to: "/services",
  },
  {
    title: "Contact",
    to: "#footer",
  },
  {
    title: "Featured missions",
    to: "/missions",
  },
];
