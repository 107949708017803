import * as React from "react";
import { getComputedClassName } from "../icon.helpers";
import type { IconType } from "../icon.types";

function Icon(originalProps: IconType) {
  const {
    white,
    blue,
    red,
    green,
    orange,
    orangeMiddle,
    grayDark,
    grayLight,
    ...props
  } = originalProps;
  props.className = getComputedClassName(originalProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 98"
      {...props}
    >
      <path
        fill="#051653"
        d="M17.733 19.24c-1.07 0-1.939.986-1.939 2.198 0 1.213.87 2.2 1.939 2.2s1.938-.987 1.938-2.2c0-1.212-.87-2.199-1.938-2.199M26.012.845H9.452V5.21h16.56z"
      />
      <path
        fill="#051653"
        d="M26.518 9.005H8.956L.332 33.862h34.967zm-8.785 18.413c-2.907 0-5.271-2.682-5.271-5.98 0-3.297 2.364-5.98 5.271-5.98s5.271 2.683 5.271 5.98-2.364 5.98-5.271 5.98M6.759 44.109h21.958l5.178-6.452H1.694zM27.851 47.904H7.612v8.539h20.24zM18.082 89.18c-.828 2.22-2.735 3.695-4.856 3.695h-1.802c-1.927 0-3.73-.87-5.075-2.443-1.344-1.572-2.053-3.696-1.995-5.883.113-4.291 3.336-7.803 7.185-7.803h1.417c4.2 0 7.702-3.821 7.702-8.585v-7.923h-4.014v7.923c0 2.339-1.626 4.41-3.688 4.41h-1.532c-2.94 0-5.702 1.225-7.777 3.602C1.573 78.55.446 81.63.474 84.965c.058 6.783 5.042 12.275 11.11 12.275h1.642c4.476 0 8.21-3.606 8.997-8.54H18.6c-.262 0-.44.27-.52.48"
      />
    </svg>
  );
}
const SvgIconHeavyCargo = React.memo(Icon);
export default SvgIconHeavyCargo;
