import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// components
import Footer from "../../components/Footer";
import ButtonBlue from "../../components/ButtonBlue";
import FeaturedComponent from "../../components/FeaturedComponent";
import CloudsCustom from "../../components/CloudsCustom";
import PaginationMobile from "../../components/PaginationMobile";

// helpers
import scrollAddClass from "../../utils/scrollAddClass";

// constants
import {
  FIFTH_DATA_SERVICE,
  FOURTH_DATA_SERVICE,
  SECOND_DATA_SERVICE,
  THIRD_DATA_SERVICE,
} from "./constants";
import { FEATURES_DATA } from "../../assets/main.constants";

// images
import planeImage from "../../assets/images/airplane-2.png";

// styles
import styles from "./styles.module.css";
import "../../styles/Services.scss";

const Service: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showPagination, setShowPagination] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMobile = useMemo(() => windowWidth < 768, [windowWidth]);
  const serviceId = useMemo(
    () => new URLSearchParams(location.search).get("serviceId"),
    [location.search]
  );
  const selectService = useMemo(
    () => FEATURES_DATA.find((item) => item.id === Number(serviceId)) || null,
    [serviceId]
  );
  const showSecondItems = useMemo(
    () =>
      isMobile && selectService
        ? selectService.offers.slice(showPagination, showPagination + 1)
        : selectService?.offers || [],
    [isMobile, selectService, showPagination]
  );

  const toFeedback = () => {
    navigate("/feedback");
  };

  const toFutures = () => {
    navigate("/missions");
  };

  useEffect(() => {
    if (serviceId === null) navigate(-1);
  }, [serviceId]);

  useEffect(() => {
    scrollAddClass(".services .block", "animate");
    scrollAddClass(
      ".services .service-mobile .first-container",
      "second-container-animate"
    );
    scrollAddClass(
      ".services .service-desktop.first-container",
      "second-container-animate"
    );
    scrollAddClass(".services .services-info .block-end", "animate");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!selectService) return null;
  return (
    <div className="services">
      <div
        className={`background`}
        style={{
          background: `linear-gradient(180deg, #72A4CB 130%, #A7BDCB 160%)`,
          paddingBottom: "100px",
        }}
      >
        <CloudsCustom />
        <div className={styles.first}>
          <img src={planeImage} alt="plane" className="plane" />
          <h2>{selectService.title}</h2>
          <p>{selectService.description}</p>
        </div>
        <div className={styles.second}>
          <div className={styles.body}>
            <h2 className={styles.title}>{SECOND_DATA_SERVICE.title}</h2>
            <div className={styles.items}>
              {showSecondItems.map((item, index) => (
                <div key={index} className={styles.item}>
                  <div>
                    <h3 className={styles.title}>{item.title}</h3>
                  </div>
                  <p className={styles.subTitle}>{item.description}</p>
                </div>
              ))}
            </div>
            <PaginationMobile
              indexShow={showPagination}
              setIndexShow={setShowPagination}
              lastIndex={selectService.offers.length - 1}
            />
            <ButtonBlue
              title="INQUIRE NOW"
              styleButton={styles.button}
              styleContainer={styles.buttonContainer}
              onClick={toFeedback}
            />
          </div>
        </div>
        <div className={styles.third}>
          <div className={styles.body}>
            <h2 className={styles.title}>{THIRD_DATA_SERVICE.title}</h2>
            <div className={styles.items}>
              {selectService.chooses.map((item, index) => (
                <div key={index} className={styles.item}>
                  {item.title && (
                    <h3 className={styles.title}>
                      {index + 1}. {item.title}
                    </h3>
                  )}
                  <p className={styles.subTitle}>{item.description}</p>
                </div>
              ))}
            </div>
            <ButtonBlue
              title="INQUIRE NOW"
              styleContainer={styles.buttonContainer}
              onClick={toFeedback}
            />
          </div>
        </div>
        <div className={styles.fourth}>
          <div className={styles.body}>
            <img src={FOURTH_DATA_SERVICE.image} alt="landscape" />
            <div className={styles.partRight}>
              {isMobile ? (
                <h2 className={styles.title}>
                  {FOURTH_DATA_SERVICE.titleFirst}{" "}
                  {FOURTH_DATA_SERVICE.titleSecond}
                </h2>
              ) : (
                <div>
                  <h2 className={styles.title}>
                    {FOURTH_DATA_SERVICE.titleFirst}
                  </h2>
                  <h2 className={styles.title}>
                    {FOURTH_DATA_SERVICE.titleSecond}
                  </h2>
                </div>
              )}
              <p className={styles.subTitle}>
                {FOURTH_DATA_SERVICE.description}
              </p>
              <div
                className={styles.button}
                onClick={toFutures}
                style={{
                  cursor: "pointer",
                }}
              >
                <p>READ MORE</p>
              </div>
            </div>
          </div>
        </div>
        <FeaturedComponent title={FIFTH_DATA_SERVICE.title} />
      </div>

      <Footer />
    </div>
  );
};

export default Service;
