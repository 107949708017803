import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// components
import StartMain from "./StartMain";
import BodyMain from "./BodyMain";

const Main: FunctionComponent = () => {
  const location = useLocation();
  const [isStart, setIsStart] = useState(true);

  useEffect(() => {
    if (location.hash.includes("aboutUs") || location.hash.includes("footer"))
      setIsStart(false);
    else setIsStart(true);
  }, [location]);

  if (isStart) return <StartMain setIsStart={setIsStart} />;
  return <BodyMain />;
};

export default Main;
