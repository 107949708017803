import image1 from "../../assets/images/missions/item-1.png";
import image2 from "../../assets/images/missions/item-2.png";
import image3 from "../../assets/images/missions/item-3.png";
import image4 from "../../assets/images/missions/item-4.png";
import image5 from "../../assets/images/missions/item-5.png";
import image6 from "../../assets/images/missions/item-6.png";
import image7 from "../../assets/images/missions/item-7.png";
import image8 from "../../assets/images/missions/item-8.png";
import image9 from "../../assets/images/missions/item-9.png";

export const FIRST_DATA_MISSIONS = {
  title: "FEATURED MISSIONS",
  subTitle:
    "Here, we showcase the most challenging, unique, and inspiring shipments that Air Alliance US has successfully delivered. From time-critical medical supplies to oversized industrial equipment, each mission highlights our team's ability to manage complex shipments across various industries and challenging scenarios.",
};

export const SECOND_DATA_CONTACTS = [
  {
    id: 1,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image1,
    to: "/",
  },
  {
    id: 2,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image2,
    to: "/",
  },
  {
    id: 3,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image3,
    to: "/",
  },
  {
    id: 4,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image4,
    to: "/",
  },
  {
    id: 5,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image5,
    to: "/",
  },
  {
    id: 6,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image6,
    to: "/",
  },
  {
    id: 7,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image7,
    to: "/",
  },
  {
    id: 8,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image8,
    to: "/",
  },
  {
    id: 9,
    title: "Emergency Medical Supply Delivery",
    description:
      "When time was critical, we coordinated a rapid response to deliver vital medical equipment across continents within 24 hours.",
    image: image9,
    to: "/",
  },
];
